import { Link, useHistory } from 'react-router-dom'
import '../../css/admin.css'

export const AdminPage = (props: { userPermission: string }) => {
  const history = useHistory()

  if (props.userPermission !== 'ROLE_ADMIN') {
    history.push('/login')
  }

  return (
    <div className="admin-wrapper">
      <h1>Account</h1>
      <ul className="admin-menu">
        <li>
          <Link className="admin-link" to="/user-list">
            Users
          </Link>
        </li>
        <li>
          <Link className="admin-link" to="/admin-campaign">
            Campaign
          </Link>
        </li>
        <li>Challenges</li>
        <li>Charities</li>
      </ul>
    </div>
  )
}
