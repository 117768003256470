import { Component } from 'react'

import '../../css/viewPledge.css'

let currentDate = new Date()
export default class Calendar extends Component<{
  firstPayment: any
  secondPayment: any
  thirdPayment: any
  lastPayment: any
  campaignStartDate: any
}> {
  state = {
    currentDate: currentDate.getTime(),
    currentMonth: currentDate.getMonth(),
    day: 24 * 60 * 60 * 1000,
  }

  compareDate = (date: any) => {
    const { currentDate } = this.state
    const firstPayment = this.props.firstPayment.getTime()
    const secondPayment = this.props.secondPayment.getTime()
    const thirdPayment = this.props.thirdPayment.getTime()
    const lastPayment = this.props.lastPayment.getTime()

    if (date.getTime() < currentDate) {
      if (
        date.getTime() === firstPayment ||
        date.getTime() === secondPayment ||
        date.getTime() === thirdPayment ||
        date.getTime() === lastPayment
      ) {
        return 'date payment-date passed-payment'
      }
      return 'date passed-date'
    } else if (date.getTime() >= currentDate) {
      if (
        date.getTime() === firstPayment ||
        date.getTime() === secondPayment ||
        date.getTime() === thirdPayment ||
        date.getTime() === lastPayment
      ) {
        return 'date payment-date rest-payment'
      }
      return 'date rest-date'
    }
  }

  checkPayment = (date: any) => {
    const firstPayment = this.props.firstPayment.getDate()
    const secondPayment = this.props.secondPayment.getDate()
    const thirdPayment = this.props.thirdPayment.getDate()
    const lastPayment = this.props.lastPayment.getDate()
    if (date.getDate() === firstPayment) {
      return firstPayment.toString()
    } else if (date.getDate() === secondPayment) {
      return secondPayment.toString()
    } else if (date.getDate() === thirdPayment) {
      return date.getDate()
    } else if (date.getDate() === lastPayment) {
      return date.getDate()
    } else {
      return ''
    }
  }

  addDate = (days: any) => {
    const { day } = this.state
    return (
      <span
        className={this.compareDate(new Date(this.props.campaignStartDate.getTime() + days * day))}
        children={this.checkPayment(new Date(this.props.campaignStartDate.getTime() + days * day))}
      ></span>
    )
  }

  render() {
    const { day } = this.state
    return (
      <div className="calendar-wrapper">
        <ul className="calendar">
          <li
            className={
              currentDate.getTime() >= this.props.campaignStartDate.getTime() + 0 * day &&
              currentDate.getTime() <= this.props.campaignStartDate.getTime() + 7 * day
                ? 'highlight'
                : 'null'
            }
          >
            <span className="week">Week 1</span>
            {this.addDate(0)}
            {this.addDate(1)}
            {this.addDate(2)}
            {this.addDate(3)}
            {this.addDate(4)}
            {this.addDate(5)}
            {this.addDate(6)}
          </li>
          <li
            className={
              currentDate.getTime() > this.props.campaignStartDate.getTime() + 7 * day &&
              currentDate.getTime() <= this.props.campaignStartDate.getTime() + 14 * day
                ? 'highlight'
                : 'null'
            }
          >
            <span className="week">Week 2</span>
            {this.addDate(7)}
            {this.addDate(8)}
            {this.addDate(9)}
            {this.addDate(10)}
            {this.addDate(11)}
            {this.addDate(12)}
            {this.addDate(13)}
          </li>
          <li
            className={
              currentDate.getTime() > this.props.campaignStartDate.getTime() + 14 * day &&
              currentDate.getTime() <= this.props.campaignStartDate.getTime() + 21 * day
                ? 'highlight'
                : 'null'
            }
          >
            <span className="week">Week 3</span>
            {this.addDate(14)}
            {this.addDate(15)}
            {this.addDate(16)}
            {this.addDate(17)}
            {this.addDate(18)}
            {this.addDate(19)}
            {this.addDate(20)}
          </li>
          <li
            className={
              currentDate.getTime() > this.props.campaignStartDate.getTime() + 21 * day &&
              currentDate.getTime() <= this.props.campaignStartDate.getTime() + 28 * day
                ? 'highlight'
                : 'null'
            }
          >
            <span className="week">Week 4</span>
            {this.addDate(21)}
            {this.addDate(22)}
            {this.addDate(23)}
            {this.addDate(24)}
            {this.addDate(25)}
            {this.addDate(26)}
            {this.addDate(27)}
          </li>
        </ul>
      </div>
    )
  }
}
