import { ModalHover } from 'react-modal-hover'
import { useEffect, useState } from 'react'

import nzmap from '../../images/mapImage/NZmap.svg'
import ukmap from '../../images/mapImage/UKmap.png'
import NameCard from './NameCardIcon'
import Profile from '../../images/mapImage/Shannon.svg'
import AucklandCity from '../../images/mapImage/AucklandCity.svg'
import Ashlee from '../../images/mapImage/Ashlee.jpg'
import SelSelfie from '../../images/selfies/sel_selfie.svg'
import coffess from '../../images/mapImage/Coffee.svg'
import gym from '../../images/mapImage/Bike.svg'
import Youthline from '../../images/mapImage/Youthline.svg'
import unseen from '../../images/mapImage/unseen.png'
import lunchOut from '../../images/mapImage/FoodDelivery.svg'
import BackgroundOne from '../../images/mapImage/impact_one.svg'
import BackgroundTwo from '../../images/mapImage/impact_two.svg'
import BackgroundThree from '../../images/mapImage/impact_three.svg'
import { Country } from '../../types/enums'

export default function ImportAotearoa(this: any, props: { country: Country }): JSX.Element {
  const [hoverStatus, setHoverState] = useState(Boolean)
  const [NZToggleName, setNZToggleName] = useState('NZ map')
  const [UKToggleName, setUKToggleName] = useState('UK map')

  const NZnamecard = (
    <NameCard
      profile={Ashlee}
      name="Ashlee"
      cityAndCaption="NZ’s youth"
      totalCount={40}
      chosenAct={lunchOut}
      charityFor={Youthline}
      chosenActDes="4 lunches out"
      charityForDes="28 mins call time"
      country={1}
      className="NZnamecard1"
    />
  )
  const NZnamecard2 = (
    <NameCard
      profile={Profile}
      name="Shannon"
      cityAndCaption="Auckland's homeless population"
      totalCount={80}
      chosenAct={coffess}
      charityFor={AucklandCity}
      chosenActDes="30 coffees"
      charityForDes="8 food parcels"
      country={1}
      className={hoverStatus === false ? 'NZnamecard2' : 'hideNZnamecard2'}
    />
  )

  const UKnamecard = (
    <NameCard
      profile={SelSelfie}
      name="Selwyn"
      cityAndCaption="a survivor develop confidence in UK"
      totalCount={60}
      chosenAct={gym}
      charityFor={unseen}
      chosenActDes="4 weeks gym membership"
      charityForDes="3 career sessions for survivors"
      country={'UK'}
      className="UKnamecard"
    />
  )

  const MyContentStyles = {
    backgroundColor: 'transparent',
    color: 'none',
    boxShadow: 'none',
    border: 'none',
    padding: '0 auto',
  }
  const MyBackgound = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  }

  const [selected, setSelected] = useState(0)
  const mapToggle = (event: any) => {
    if (event.target.value === 'NZ map') {
      setSelected(0)
    } else if (event.target.value === 'UK map') {
      setSelected(1)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 850) {
        setNZToggleName('NZ map')
        setUKToggleName('UK map')
      } else {
        setNZToggleName('NZ')
        setUKToggleName('UK')
      }
    })
  }, [])

  return (
    <div className="outermap">
      <div className="h1-normal-section">
        <h1>Our impact across Aotearoa</h1>
        <h1 style={{ fontSize: '1.56em' }}>See what others have to say about Give Better</h1>
      </div>
      <div className="impact-section">
        <div className="impact-quote">
          <img src={BackgroundOne} alt="BackgroundOne" className="background-one" />
          <span>
            "This Challenge is a good opportunity to look at my spending and think about how it can
            have an impact beyond my needs and wants. I also like to take on and beat a good
            challenge!"
            <p>- Reuel</p>
          </span>
        </div>
        <div className="impact-quote">
          <img src={BackgroundTwo} alt="BackgroundTwo" className="background-two" />
          <span>
            "It's amazing how much money is locked up in my spending habits. By making a small
            change, I can suddenly give far more than I thought I could."
            <p>- Selwyn</p>
          </span>
        </div>
        <div className="impact-quote">
          <img src={BackgroundThree} alt="BackgroundThree" className="background-three" />
          <span>
            "The Give Better Challenge provides a great opportunity to turn our minds to how we can
            be generous with our everyday decision making, and save money on 'meh' things in the
            process too!"
            <p>- Katie</p>
          </span>
        </div>
      </div>
      <div className="h1-normal-section">
        <h1 style={{ fontSize: '1.56em' }}>
          Have a look at pledges people have made and the impact that they're making!
        </h1>
      </div>

      <div className="mapanddots">
        <div className="toggle-wrapper">
          <input
            className="radio"
            type="radio"
            value="NZ map"
            name="spending"
            onChange={mapToggle}
            onClick={() => setSelected(0)}
            defaultChecked
          />
          &nbsp; {NZToggleName}
          <input
            className="radio"
            type="radio"
            value="UK map"
            name="spending"
            onClick={() => setSelected(1)}
            onChange={mapToggle}
            style={{ marginLeft: '10px' }}
          />
          &nbsp; {UKToggleName}
        </div>
        {selected === 0 && (
          <div className="nz-map">
            <img src={nzmap} alt="nzMap" style={{ width: '100%', height: '100%' }} />
            <ul className="dots-wrapper">
              <li>
                <h1 className="toHover1"> </h1>
                {NZnamecard2}
              </li>
              <li>
                <ModalHover
                  BackgroundStyles={MyBackgound}
                  ContentStyles={MyContentStyles}
                  onHover={NZnamecard}
                >
                  <h1
                    className="toHover1"
                    onMouseEnter={() => {
                      setHoverState(true)
                    }}
                    onMouseLeave={() => {
                      setHoverState(false)
                    }}
                  >
                    {' '}
                  </h1>
                </ModalHover>
              </li>
            </ul>
          </div>
        )}
        {selected === 1 && (
          <div className="uk-map">
            <img src={ukmap} alt="ukMap" style={{ width: '100%', height: '100%' }} />
            <ul className="UKdots-wrapper">
              <li>
                <h1 className="toHover1"> </h1>
                {UKnamecard}
              </li>
            </ul>
          </div>
        )}
        {selected === 0 && (
          <ul className="hidden-data">
            <li>{NZnamecard}</li>
            <li>{NZnamecard2}</li>
          </ul>
        )}
        {selected === 1 && (
          <ul className="hidden-data">
            <li>{UKnamecard}</li>
          </ul>
        )}
      </div>
    </div>
  )
}
