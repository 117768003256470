import Calculator from '../Calculator/Calculator'

export default function CalculateImpact(props: {
  itemChosen: string
  totalAmount: Number
  totalTimes: Number
  parkingCost: Number
  perCost: Number
  weeklyAmount: Number
  back: Number
  country: any
  parkingChecked: boolean
}) {
  return (
    <div>
      <Calculator
        itemChosen={props.itemChosen}
        totalAmount={props.totalAmount}
        totalTimes={props.totalTimes}
        parkingCost={props.parkingCost}
        perCost={props.perCost}
        weeklyAmount={props.weeklyAmount}
        back={props.back}
        country={props.country}
        parkingChecked={props.parkingChecked}
      />
    </div>
  )
}
