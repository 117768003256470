import '../../src/css/about.css'
import SelSelfie from '../../src/images/selfies/sel_selfie.svg'
import KatSelfie from '../../src/images/selfies/Kat_selfie.jpeg'
import RoxySelfie from '../../src/images/selfies/Roxy_selfie.jpeg'
import MingSelfie from '../../src/images/selfies/Ming_selfie.jpg'
import AustinSelfie from '../../src/images/selfies/Austin_selfie.jpeg'
import CraigSelfie from '../../src/images/selfies/Craig_selfie.jpeg'
import IvanSelfie from '../../src/images/selfies/Ivan_selfie.jpeg'
export const About = () => {
  return (
    <div style={{ maxWidth: '1080px', margin: '0 auto', alignContent: 'center' }}>
      <div className="about-section">
        <div className="about-headers">
          <h2>What is Give Better?</h2>
        </div>
        <div className="about-top-content">
          <p>
            We are a charitable trust registered in New Zealand. We dream of a movement of people,
            empowered to make small changes to our spending habits. So, together, we unleash a tidal
            wave of generosity.
          </p>
        </div>
        <div className="about-headers">
          <h2>About our story</h2>
        </div>
        <div className="about-body-normal">
          <p>
            It all began because Give Better's founder, Selwyn, was pretty bad with money. But he
            slowly began to ask himself a question:
          </p>
        </div>
        <div className="about-body-indent">
          <p className="about-body-question">
            How much potential for world-changing good is tied up in spending habits that sometimes
            don't even bring me that much joy?
          </p>
        </div>
        <div className="about-body-normal">
          <p>
            You know, the daily Starbucks or lunchtime sushi we just buy out of habit. Or that third
            streaming service or gym membership we rarely use. It's not about feeling guilty. But
            what if, just maybe, there might be a better way?
          </p>
          <li>
            <span className="emphasize">Better for the world </span>- as we turn spending into
            impact
          </li>
          <li>
            <span className="emphasize">And maybe even better for us too </span>- as we grow in
            financial awareness, feel more in control of our spending, and discover why science
            tells us there is sometimes more joy in giving than spending on ourselves.
          </li>
        </div>
        <div className="about-headers">
          <h2>Our team</h2>
        </div>
        <div className="team-selfies">
          <div>
            <img className="selfie normalSizeSelfie" src={SelSelfie} alt="Sel Selfie" />
            <p className="name">Selwyn Coles</p>
            <p>Founder, chief motivator</p>
          </div>
          <div>
            <img className="selfie normalSizeSelfie" src={CraigSelfie} alt="Craig Selfie" />
            <p className="name">Craig</p>
            <p>Trustee</p>
          </div>
          <div>
            <img className="tallSizeSelfie selfie" src={IvanSelfie} alt="Ivan Velickovic Selfie" />
            <p className="name">Ivan Velickovic</p>
            <p>Designer</p>
          </div>
          <div>
            <img className="selfie normalSizeSelfie" src={KatSelfie} alt="Kat Setiadarma Selfie" />
            <p className="name">Kat Setiadarma</p>
            <p>Developer</p>
          </div>
          <div>
            <img className="selfie normalSizeSelfie" src={RoxySelfie} alt="Roxy Dao Selfie" />
            <p className="name">Roxy Dao</p>
            <p>Developer</p>
          </div>
          <div>
            <img className="selfie normalSizeSelfie" src={MingSelfie} alt="Ming Yang Selfie" />
            <p className="name">Ming Yang</p>
            <p>Developer</p>
          </div>
          <div>
            <img className="selfie normalSizeSelfie" src={AustinSelfie} alt="Austin Li Selfie" />
            <p className="name">Austin Li</p>
            <p>Developer</p>
          </div>
        </div>
      </div>
    </div>
  )
}
