import { useAuth } from '../../hooks/useAuth'

export default function Cancellation(props: { remainingPayment: any; name: String }) {
  const auth = useAuth()

  const updatePledge = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/cancel`, {
      method: 'PUT',
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      {/* {(close: () => void)=>( */}
      <div className="modal">
        <div className="top-caption">
          <h1>Cancel {props.name}'s pledge</h1>
          <p>The {props.remainingPayment} remaining payments for your pledge will be cancelled. </p>
          <p>Are you sure you want to cancel your pledge?</p>
        </div>
        <a
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#BB4C4C',
            marginTop: '20px',
            cursor: 'pointer',
          }}
          href="/login"
          onClick={updatePledge}
        >
          Yes, I want to cancel my pledge
        </a>
        <br></br>
      </div>
    </>
  )
}
