export default function DateFormat(date: number) {
  var i = date % 10,
    j = date % 100

  if (i === 1 && j !== 11) {
    return date + 'st'
  }
  if (i === 2 && j !== 12) {
    return date + 'nd'
  }
  if (i === 3 && j !== 13) {
    return date + 'rd'
  }
  return date + 'th'
}
