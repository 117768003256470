import * as yup from 'yup'

export const resetPasswordSchema = yup.object().shape({
  resetToken: yup.string().required('Token is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  passwordConfirmation: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const recoverPasswordSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
})

export const registerPasswordSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  Country: yup
    .string()
    .required('Please select a country')
    .oneOf(['New Zealand', 'United Kingdom']),
  terms: yup.boolean().oneOf([true], 'You must accept the above terms to participate in the challenge').required(),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
})
