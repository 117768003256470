import { Redirect } from 'react-router-dom'

export const ViewPledge = () => {
  let isAuth = false

  const loginAuth = localStorage.getItem('isAuthenticated')

  if (loginAuth !== null) {
    isAuth = JSON.parse(loginAuth)
  }

  if (isAuth) {
    return <Redirect to="/summary" />
  } else {
    return <Redirect to="/login" />
  }
}
