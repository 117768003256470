import '../../src/css/privacy.css'

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-wrapper">
      <h5>Give Better Privacy Policy</h5>
      <p>
        Hi! We are the Give Better Trust (“<b>Give Better</b>”). We are a registered charitable
        trust in New Zealand. If you are reading this, you are probably interested in getting
        involved with us, including through the Give Better Website (“<b>Website</b>”). This likely
        means you have joined, or are considering joining, one of our challenges - where we
        challenge people to give up an area of spending for a period, and donate the savings (the “
        <b>Give Better Challenge</b>”).
      </p>
      <p>
        At Give Better, we take your privacy seriously. We comply with the New Zealand Privacy Act
        1993 (“<b>Privacy Act</b>”), which sets out the obligations that organisations like us have
        when it comes to handling your personal information. We also comply with applicable laws in
        the United Kingdom (“<b>UK</b>”): the Data Protection Act 2018; the General Data Protection
        Regulation (GDPR); and the Privacy and Electronic Communications (EC Directive) Regulations
        (PECR) 2003.
      </p>
      <p>
        We know that by giving us your personal information, you trust us to handle it securely,
        only use it for the purposes we collected it for, and generally keep it safe.
      </p>
      <p>
        We are good people — we promise. We only collect the information we, and relevant third
        parties, need to operate our Website and run the Give Better Challenge.
      </p>
      <p>
        This Privacy Policy explains:
        <ul>
          <li>what “personal information” is;</li>
          <li>what personal information Give Better collects, and how we collect it;</li>
          <li>
            how Give Better and some third parties use and disclose your personal information;
          </li>
          <li>storage and security information;</li>
          <li>
            your rights to request access to, and the correction of, your personal information; and
          </li>
          <li>our contact information.</li>
        </ul>
      </p>
      <p>
        From time to time, we might make changes to this Privacy Policy. If we do, we will send you
        an email letting you know that the Privacy Policy has been updated. If you wish to seek
        further information about your privacy rights, please visit{' '}
        <a href="https://www.privacy.org.nz">www.privacy.org.nz</a> (if you are in NZ) or{' '}
        <a href="https://www.ico.org.uk">www.ico.org.uk</a> (if you are in the UK). By providing your
        personal information or using our services (like our Website), you agree to this Privacy
        Policy.
      </p>
      <p>
        By providing your personal information or using our services (like our website or the App),
        you agree to this Privacy Policy.
      </p>
      <h5>Personal Information</h5>
      <p>
        “Personal information” is any information about you that identifies you, or allows your
        identity to be ascertained.
      </p>
      <h5> Personal information that Give Better collects, and how we collect it</h5>
      <p>
        The type of information Give Better collects depends on how you are interacting with us.
      </p>
      <p className="italics">If you Browse our Website</p>
      <p>
        If you are just browsing our Website, our host records your server address, domain name, the
        date and time of the visit and the pages viewed. This information may be collected using
        cookies (data sent to your web browser, which generally allows our site to interact more
        efficiently with your computer). If you disable the use of cookies, your use of our site may
        be affected.
      </p>
      <p className="italics">If you sign up for the Give Better Challenge</p>
      <p>
        If you sign up to the Give Better Challenge, we will collect your name, email address as
        well the amounts you are donating and which area of spending you are ‘giving up’. We will
        also collect your credit card details, so that you can make donations through the Website.
        But this information is held and stored securely by the third-party,{' '}
        <a href="https://stripe.com/gb/connect">Stripe Connect</a>, rather than by Give Better.
        Stripe Connect is one of the most popular and secure payment platforms in the world.
      </p>
      <p>
        Importantly, we will ask your permission to pass on certain personal information to the
        charity you are donating to (your “<b>Partner Charity</b>”) so they can then issue you a
        receipt for your donations. This personal information is: your name, email address, and the
        amounts and dates of your donations. Because the issuing of a receipt from your Partner
        Charity is essential to the Give Better Challenge, you will only be able to sign up and
        participant in the Give Better Challenge if you ‘opt in’ and give your permission for this
        personal information to be passed on.
      </p>
      <p className="italics">If you ‘opt in’ to be contacted by your Partner Charity</p>
      <p>
        You will also have the option to ‘opt in’ and give permission for your Partner Charity to
        contact you for other legitimate reasons. This likely means the Partner Charity will add
        your email address to their general database of supporters and donors. You do not have to
        ‘opt in’ to this to participate in the Give Better Challenge. For more information on how
        your Partner Charity will handle your personal information, please read the Privacy Policy
        on their website.
      </p>
      <h5>How Give Better and third parties use and disclose your personal information</h5>
      <p className="italics">Give Better</p>
      <p>
        We collect this personal information for various purposes, including to:
        <ul>
          <li>ensure the effective operation and maintenance of our website;</li>
          <li>
            allow you to use the Website (including its interactive features), and to maintain the
            Website;
          </li>
          <li>notify you of any changes to the Website, or any relevant terms and conditions;</li>
          <li>to keep you up-to-date with Give Better news and developments;</li>
          <li>to provide customer care and support;</li>
          <li>
            allow us to analyse or examine usage data so we can continue to improve our Website;
          </li>
          <li>monitor how people are using the Website;</li>
          <li>detect, prevent, and address technical issues;</li>
          <li>help us to respond to enquiries;</li>
          <li>receive feedback and deal with complaints;</li>
          <li>improving our services and procedures; and</li>
          <li>meeting our regulatory and legal obligations.</li>
        </ul>
      </p>
      <p>
        Give Better might also need to use your personal information if it believes doing so is
        necessary to:
        <ul>
          <li>comply with its legal obligations;</li>
          <li>protect and defend its rights or property;</li>
          <li>
            prevent or investigate possible wrongdoing in connection with its website or the App;
          </li>
          <li>protect the personal safety of other users of the website or the App; or</li>
          <li>protect against legal liability.</li>
        </ul>
      </p>
      <p>
        We may also disclose personal information where you consent to us doing so. That consent may
        be written, verbal or implied from your conduct.
      </p>
      <p>
        By providing your email address, you consent to us sending you information connected with
        the above collection and use purposes. The messages you receive from us will have
        instructions for how you can remove yourself from our mailing list if you wish.
      </p>
      <p>
        We may also need to use and disclose your information if we are selling or changing the
        ownership or control of Give Better.
      </p>
      <p className="italics">Partner charities</p>
      <p>
        As well as using this information ourselves, Give Better will pass on specific personal
        information to its partner charities. This may include your name, email address, and the
        amounts and dates of your donations. This information enables the Partner Charity to send
        you a receipt for your donations. It might also assist them to understand and improve their
        use of our Website.
      </p>
      <p>
        You also have the option to ‘opt in’ and give permission for your Partner Charity to contact
        you for other legitimate reasons. This likely means the Partner Charity will add your email
        address to their general database of supporters and donors. You do not have to ‘opt in’ to
        participate in the Give Better Challenge. For more information on how your Partner Charity
        will handle your personal information, please read the Privacy Policy on their website.
      </p>
      <p className="italics">StripeConnect</p>
      <p>
        Give Better uses a third party called{' '}
        <a href="https://stripe.com/gb/connect">StripeConnect</a> to help process your donations.
        Give Better needs to give StripeConnect access to the relevant personal information
        StripeConnect needs to help process your payments. This includes your name and email
        address, as well as your relevant credit card or banking details. Your credit card or
        banking information is held and stored securely by{' '}
        <a href="https://stripe.com/gb/connect">StripeConnect</a>, rather than by Give Better.
        Stripe Connect is one of the most popular and secure payment platforms in the world.
      </p>
      <p className="italics">Other third parties</p>
      <p>
        We may employ third party companies and individuals to facilitate our website or the App ("
        <b>Service Providers</b>"), to provide services on our behalf, perform services in relation
        to the website or App, or help us to analyse how the website or App are being used.
      </p>
      <p>
        These Service Providers have access to your personal information only to perform these tasks
        on our behalf and are obligated not to disclose or use it for any other purpose.
      </p>
      <p className="italics">Links to Other Sites</p>
      <p>
        Our Website may contain links to other sites that are not operated by us. If you click on a
        third party link, you will be directed to that third party's site. We strongly advise you to
        review the Privacy Policy of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any third party sites or
        services
      </p>
      <h5>Storage and security of personal information</h5>
      <p>
        The security of your data is important to us, but remember that no method of transmission
        over the Internet, or method of electronic storage, is 100% secure. While we strive to use
        commercially acceptable means to protect your personal information, we cannot guarantee its
        absolute security.
      </p>
      <p>
        If you believe that the privacy of your personal information has been compromised, please
        let us know. You can make a complaint by contacting the person you were dealing with or by
        sending us an email to <a href="mailto:sel@givebetter.nz">sel@givebetter.nz</a>. We will
        respond to complaints as soon as possible.
      </p>
      <h5>Updating and accessing your personal information</h5>
      <p>
        We do everything we can to ensure that the personal information we hold is accurate,
        complete and up-to-date whenever we collect or use it. This means that we might sometimes
        ask you to tell us if there are any changes to your personal information. If you find that
        information we hold about you is incorrect, please contact us immediately and we will
        correct it.
      </p>
      <p>
        Subject to certain grounds for refusal in law, you have the right to access your readily
        retrievable personal information that we hold and to request a correction to your personal
        information. We may ask to see evidence to confirm you are the individual to whom the
        personal information relates.
      </p>
      <h5>Your Rights</h5>
      <p>
        If granted by applicable laws, you can also exercise a variety of rights regarding our use
        of your data by contacting us using the contact details below. You can:
        <ul>
          <li>ask us for a copy of the personal information we have about you</li>
          <li>ask us to correct any incorrect data we have about you</li>
          <li>ask us to delete your data</li>
          <li>
            object to any processing we do on the basis of legitimate interests or to any automated
            decision-making
          </li>
          <li>ask us to restrict the processing of your data</li>
        </ul>
        Note that you have the right to lodge a complaint with the UK Information Commissioner’s
        Office, or the NZ Privacy Commissioner.
      </p>
      <h5>Contacting us</h5>
      <p>
        You can contact Give Better by sending us an email at{' '}
        <a href="mailto:sel@givebetter.nz">sel@givebetter.nz</a>, or through our website at{' '}
        <a href="https://givebetter.nz">www.givebetter.nz</a>
      </p>
    </div>
  )
}
