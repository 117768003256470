import Secure from '../../images/Vector.svg'
import ConcernedCalendar from '../../images/concerned_calendar.svg'
import ConcernedControl from '../../images/concerned_control.svg'
import ConcernedDollar from '../../images/concerned_dollar.svg'
import { Country } from '../../types/enums'
import DateFormat from '../Reusable/DateFormat'

export default function ConcernedMoney(props: {
  country: Country
  campaignName: string[]
  paymentStart: Date
  campaignIsActive: boolean
}) {
  const day = 24 * 60 * 60 * 1000 //Doesn't account for daylights

  const firstPayment = DateFormat(props.paymentStart.getDate())
  const secondPayment = new Date(props.paymentStart.getTime() + 7 * day)
  const secondPaymentCheck = DateFormat(secondPayment.getDate())
  const thirdPayment = new Date(props.paymentStart.getTime() + 14 * day)
  const thirdPaymentCheck = DateFormat(thirdPayment.getDate())
  const fourthPayment = new Date(props.paymentStart.getTime() + 21 * day)
  const fourthPaymentCheck = DateFormat(fourthPayment.getDate())

  const paymentDates = () => {
    return (
      <span>
        {props.campaignName[0]}. The dates are the{' '}
        <b>
          {firstPayment}, {secondPaymentCheck}, {thirdPaymentCheck}, {fourthPaymentCheck}
        </b>
        .
      </span>
    )
  }

  return (
    <div>
      <span id="concerned-section"></span>
      <div className="h1-normal-section">
        <h1>Concerned about money?</h1>
      </div>
      <div className="bottomBoxes">
        <div className="square">
          <h3 className="concerned-h3">
            <img src={Secure} className="concerned-img" alt="secure"></img>&nbsp;Secure
          </h3>
          <p>
            All your donations are processed securely through Stripe (trusted by companies such as
            Google, Oxfam and Xerox).
          </p>
        </div>
        <div className="square">
          <h3 className="concerned-h3">
            <img src={ConcernedCalendar} className="concerned-img" alt="calendar"></img>
            &nbsp;Weekly Payments
          </h3>
          <p>
            Donations are set to go out at the end of every week
            {!props.campaignIsActive ? '.' : paymentDates()}
          </p>
        </div>
        <div className="square">
          <h3 className="concerned-h3">
            <img src={ConcernedControl} className="concerned-img" alt="control"></img>
            &nbsp;Control
          </h3>
          <p>You can cancel your remaining donations and view your details at any time.</p>
        </div>
        <div className="square">
          <h3 className="concerned-h3" id="secure">
            <img src={ConcernedDollar} className="concerned-img" alt="dollar"></img>&nbsp;Fees
          </h3>
          <p>
            Each donation has a transaction fee of{' '}
            <span>{props.country === Country.NZ ? '2.9% + $0.30' : '2.9% + £0.20'}</span>. This is
            simply to cover costs as we are a non-profit organisation.
          </p>
        </div>
      </div>
    </div>
  )
}
