import { useState } from 'react'

export function useChallenges() {
  const [challengeIDs, setChallengeID] = useState<any>([])
  const [challengeNames, setChallengeNames] = useState<any>([])
  const [challengeLandingIcons, setChallengeLandingIcons] = useState<any>([])
  const [challengeDesc, setChallengeDesc] = useState<any>([])
  const [hashTag, setHashTag] = useState<any>([])
  const [challengeIcons, setChallengeIcons] = useState<any>([])
  const [hashTagLink, setHashTagLink] = useState<any>([])
  const [challengeDefaultNames, setChallengeDefaultNames] = useState<any>([])
  const [challengeDefaultLandingIcons, setChallengeDefaultLandingIcons] = useState<any>([])

  const getDefaultChallenges = async (country: String) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/challenge/default/${country}`)
        .then((res) => res.json())
        .then((data) => {
          setChallengeDefaultNames(data.map((item: any) => item.name))
          setChallengeDefaultLandingIcons(data.map((item: any) => item.landing_page_icon))
        })
    } catch (e) {
      console.log(e)
    }
  }

  const getChallenges = async (country: String) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/challenge/campaign/${country}`)
        .then((response) => response.json())
        .then((data) => {
          setChallengeID(data.map((item: any) => item.id))
          setChallengeNames(data.map((item: any) => item.name))
          setChallengeLandingIcons(data.map((item: any) => item.landing_page_icon))
          setChallengeIcons(data.map((item: any) => item.icon))
          setChallengeDesc(data.map((item: any) => item.description))
          setHashTag(data.map((item: any) => item.hashtag))
          setHashTagLink(data.map((item: any) => item.hashtag_link))
        })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getChallenges,
    challengeNames,
    challengeIcons,
    challengeLandingIcons,
    challengeDesc,
    challengeIDs,
    hashTag,
    hashTagLink,
    getDefaultChallenges,
    challengeDefaultNames,
    challengeDefaultLandingIcons,
  }
}
