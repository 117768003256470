import { Link } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'

import logo from '../../images/titlePart/GBLogo.svg'
import arrow from '../../images/titlePart/arrow.svg'
import calendar from '../../images/titlePart/calendar.svg'
import CoffeeAnimation from './CoffeeAnimation'
import DollarAnimation from './DollarAnimation'
import { Country } from '../../types/enums'
import { usePledge } from '../../hooks/pledge'
import { useRecipient } from '../../hooks/recipient'

export default function TitlePart(props: {
  country: Country
  campaignName: string[]
  campaignStart: Date
  campaignEnd: Date
  campaignIsActive: boolean
  recipientLogo: any[]
  defaultRecipientLogos: any[]
}) {
  const countryString = props.country === Country.NZ ? 'NZ' : 'UK'
  const pledgeCounts = usePledge()
  const currentDate = useMemo(() => new Date(), [])
  const [remainingDays, setRemainingDays] = useState(Number)
  const [remainingDaysFormat, setRemainingDaysFormat] = useState(String)
  const [pledgeCampaignCount, setPledgeCampaignCount] = useState('')
  const [pledgeCampaignAmount, setPledgeCampaignAmount] = useState('')
  const [joinStatus, setJoinStatus] = useState(Boolean)

  useEffect(() => {
    const getInfo = async () => {
      pledgeCounts.getPledgeCampaignTotal(countryString)
      pledgeCounts.getPledgeCampaignAmount(countryString)
    }
    getInfo()
  }, [props.country])

  useEffect(() => {
    const remainingDays = Math.ceil(
      (props.campaignStart.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
    )
    if (remainingDays < 0) {
      if (remainingDays < -7) {
        setJoinStatus(false)
      } else {
        setJoinStatus(true)
      }
      setRemainingDays(0)
    } else {
      setJoinStatus(true)
      setRemainingDays(remainingDays)
    }
  }, [props.campaignStart, currentDate])

  useEffect(() => {
    if (remainingDays <= 9) {
      setRemainingDaysFormat(remainingDays.toString().padStart(2, '0'))
    } else {
      setRemainingDaysFormat(remainingDays.toString())
    }
  }, [remainingDays])

  const dayText = remainingDays === 1 ? 'day' : 'days'

  useEffect(() => {
    if (pledgeCounts.totalPledgeByCampaign <= 0) {
      setPledgeCampaignCount('heaps of')
      setPledgeCampaignAmount(props.country === Country.NZ ? 'heaps' : 'heaps')
    } else {
      setPledgeCampaignCount(pledgeCounts.totalPledgeByCampaign.toString())
      const currency = props.country === Country.NZ ? '$' : '£'
      setPledgeCampaignAmount(currency + pledgeCounts.totalPledgeAmountByCampaign.toString())
    }
  }, [pledgeCounts, props.country])

  const othersFormat = pledgeCampaignCount.toString() === '1' ? 'other' : 'others'

  const titleH1 = (
    <h1 style={{ fontSize: '2.1em', color: '#525252', fontFamily: 'Bitter' }}>
      Join us {!props.campaignIsActive ? '' : 'next ' + props.campaignName[0] + ' '} for the Give
      Better Challenge!
    </h1>
  )

  const joinDisable = (
    <Link
      to="/viewPledge"
      style={{
        fontSize: 24,
        fontWeight: 'bold',
        fontFamily: 'Bitter',
        color: '#67B4D6',
        textDecoration: 'none',
      }}
    >
      Campaign has now closed. Stay tuned for the future campaigns
    </Link>
  )

  function JoinDisplay() {
    if (currentDate < props.campaignEnd && props.campaignIsActive) {
      return (
        <button className="challenge-button">
          <Link to="/challenge" style={{ color: 'white', textDecoration: 'none' }}>
            Join the {props.campaignName[0]} {props.campaignName[1]}
          </Link>
        </button>
      )
    } else {
      return <p>{joinDisable}</p>
    }
  }

  function daysRemainingCheck() {
    if (remainingDays > 0) {
      return (
        <span>
          Starting in <img src={calendar} alt="calendar" />
          <span>
            <span className="day-left">{remainingDaysFormat}</span>
            {dayText}
          </span>
        </span>
      )
    } else {
      return <span>The campaign has started</span>
    }
  }

  function displayCharity() {
    if (!props.campaignIsActive) {
      return props.defaultRecipientLogos.map((item: any, index: any) => {
        return (
          <img
            key={index}
            src={item}
            alt={'charity' + index}
            style={{
              width: '160px',
              height: '110px',
            }}
          />
        )
      })
    } else {
      return props.recipientLogo.map((item: any, index: any) => {
        return (
          <img
            key={index}
            src={item}
            alt={'charity' + index}
            style={{
              width: '160px',
              height: '110px',
            }}
          />
        )
      })
    }
  }

  return (
    <div>
      <div className="title-wrapper">
        <fieldset>
          <legend>{titleH1}</legend>
          <legend>
            <div className="icon-wrapper">
              <div>
                <CoffeeAnimation />
                <h4>Give Up</h4>
              </div>
              <div>
                <img className="arrow" src={arrow} alt="arrow" />
              </div>
              <div>
                <DollarAnimation />
                <h4>Free Up</h4>
              </div>
              <div>
                <img className="arrow" src={arrow} alt="arrow" />
              </div>
              <div>
                <img className="logo" src={logo} alt="logo" style={{ height: 60, width: 300 }} />
              </div>
            </div>
            <p
              style={{
                fontFamily: 'Bitter',
                fontWeight: '500',
                fontSize: '24px',
                color: '#313131',
                margin: '0',
              }}
            >
              Make an impact with our partners
            </p>
            <div className="charity-title-wrapper">{displayCharity()}</div>
          </legend>
        </fieldset>
      </div>
      {!props.campaignIsActive ? (
        <></>
      ) : (
        <p className="title-text">
          {daysRemainingCheck()}, join <span>{pledgeCampaignCount}</span> {othersFormat} who've
          pledged
          <span>&nbsp;{pledgeCampaignAmount}!</span>
        </p>
      )}
      {JoinDisplay()}
    </div>
  )
}
