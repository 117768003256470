import { useEffect, useState } from 'react'

export default function CommuteCalculator(props: {
  itemChosen: string
  totalAmount: Number
  parkingCost: Number
  perCost: Number
  weeklyAmount: Number
  back: Number
  remainingWeek: number
}) {
  const [times, setTimes] = useState(0)
  const [parking, setParking] = useState(0)
  const [perCost, setPerCost] = useState(0)
  const [weeklyCost, setWeeklyCost] = useState(0)
  const [total, setTotal] = useState(0)
  const [frequency, setFrequency] = useState('weekly')
  const [parkingChecked, setParkingChecked] = useState(false)

  const weeklyChange = (event: any) => {
    if (event.target.value === 'monthly') {
      setFrequency(event.target.value)
      PubSub.publish('times', props.remainingWeek)
      PubSub.publish('total', ((parking + perCost) / 4) * props.remainingWeek)
      PubSub.publish('weeklyAmount', total / props.remainingWeek)
    } else if (event.target.value === 'weekly') {
      setFrequency(event.target.value)
      PubSub.publish('times', props.remainingWeek)
      PubSub.publish('total', (parking + perCost) * props.remainingWeek)
      PubSub.publish('weeklyAmount', parking + perCost)
    }
  }

  const parkingCheckbox = (event: any) => {
    if (event.target.checked === true && frequency === 'monthly') {
      PubSub.publish('total', (perCost / 4) * props.remainingWeek)
      PubSub.publish('parkingChecked', true)
      setParkingChecked(event.target.checked)
      setParking(0)
    } else if (event.target.checked === true && frequency === 'weekly') {
      PubSub.publish('total', perCost * props.remainingWeek)
      PubSub.publish('parkingChecked', true)
      setParkingChecked(event.target.checked)
      setParking(0)
    } else if (event.target.checked === false) {
      PubSub.publish('parkingChecked', false)
      setParkingChecked(event.target.checked)
    }
  }

  useEffect(() => {
    if (frequency === 'monthly') {
      setTotal(((parking + perCost) / 4) * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('times', props.remainingWeek)
      PubSub.publish('total', total)
      PubSub.publish('parking', parking)
      PubSub.publish('weeklyAmount', weeklyCost)
      PubSub.publish('parkingChecked', parkingChecked)
    } else if (frequency === 'weekly') {
      setTotal((parking + perCost) * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('times', props.remainingWeek)
      PubSub.publish('total', total)
      PubSub.publish('parking', parking)
      PubSub.publish('weeklyAmount', weeklyCost)
      PubSub.publish('parkingChecked', parkingChecked)
    }
  }, [parking, total, weeklyCost, times])

  const updateParking = (event: any) => {
    const value = event.target.value * 1
    if (frequency === 'monthly') {
      setParking(value)
    } else if (frequency === 'weekly') {
      setParking(value)
      PubSub.publish('parking', value)
      PubSub.publish('parkingChecked', false)
    } else {
      setTimes(0)
      PubSub.publish('total', 0)
    }
  }

  useEffect(() => {
    if (frequency === 'monthly') {
      setTotal(((parking + perCost) / 4) * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('times', 1)
      PubSub.publish('total', total)
      PubSub.publish('perCost', perCost)
      PubSub.publish('parking', parking)
      PubSub.publish('weeklyAmount', weeklyCost)
    } else if (frequency === 'weekly') {
      setTotal((parking + perCost) * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('times', props.remainingWeek)
      PubSub.publish('total', total)
      PubSub.publish('perCost', perCost)
      PubSub.publish('weeklyAmount', weeklyCost)
    }
  }, [perCost, total, weeklyCost])

  const updateCost = (event: any) => {
    let value = event.target.value * 1
    if (frequency === 'monthly') {
      setPerCost(value)
    } else if (value.toString() === event.target.value || frequency === 'weekly') {
      PubSub.publish('parkingChecked', parkingChecked)
      setPerCost(value)
    } else {
      PubSub.publish('total', 0)
    }
  }

  const payForParking = () => {
    if (parkingChecked === false) {
      return (
        <div>
          <input
            className="text-box"
            type="text"
            name="how much"
            onChange={updateParking}
            placeholder={props.back === 0 ? 'e.g. 0.00' : String(props.parkingCost)}
            pattern="^\d+(\.)?(\d)*\d*$"
          />
        </div>
      )
    } else if (parkingChecked === true) {
    }
  }

  return (
    <>
      <div className="calculator-body">
        <h2>Your spending</h2>
        <div className="calculator-radio-wrapper">
          <input
            className="radio"
            type="radio"
            value="weekly"
            name="spending"
            onChange={weeklyChange}
            defaultChecked
          />
          &nbsp;Weekly&nbsp;&nbsp;
          <input
            className="radio"
            type="radio"
            value="monthly"
            name="spending"
            onChange={weeklyChange}
          />
          &nbsp;Monthly
        </div>
        <div className="times-wrapper">
          <span>How much do you spend on petrol?</span>
          <div>
            <input
              className="text-box"
              type="text"
              name="how many"
              onChange={updateCost}
              placeholder={props.back === 0 ? 'e.g. 1' : String(perCost)}
              pattern="^\d+$"
            />
          </div>
        </div>
        <div className="perCost-wrapper">
          <span>How much do you spend on parking?</span>
          <div className="parking-checkbox" style={{ paddingTop: '0.5em' }}>
            <input type="checkbox" name="parking" value="parking" onChange={parkingCheckbox} />I
            don't pay for parking
          </div>
          <p
            className={
              parkingChecked === false && parking === 0 ? 'validation-show' : 'validation-disappear'
            }
            style={{ fontSize: '13px', marginTop: '7px', marginBottom: '0px' }}
          >
            Please check the box if you don't pay for parking
          </p>
          {payForParking()}
        </div>
      </div>
    </>
  )
}
