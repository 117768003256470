import { useState } from 'react'
import { Button } from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import ReactLoading from 'react-loading'
import { registerPasswordSchema } from '../../components/Authorization/YupSchema'
import arrow2 from '../../images/stepperarrow2.svg'
import '../../css/authorization.css'

export const RegistrationPage = (props: {
  charityID: Number
  challengeID: Number
  campaignID: Number
  totalAmount: Number
  totalTimes: Number
  perCost: Number
}) => {
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [userAddress, setAddress] = useState<string>('')
  const [optInGiveBetter, setOptInGiveBetter] = useState<boolean>(false)
  const [optInCharity, setOptInCharity] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(registerPasswordSchema),
  })

  const userCountry = (e: any) => {
    if (e.target.value === 'New Zealand') {
      setCountry('NZ')
    } else if (e.target.value === 'United Kingdom') {
      setCountry('UK')
    }
  }

  const checkLoading = () => {
    if (isLoading === true) {
      PubSub.publish('loading', true)
    } else if (isLoading === false) {
      PubSub.publish('loading', false)
    }
  }

  checkLoading()

  const registration = async () => {
    setIsLoading(true)

    await fetch(`${process.env.REACT_APP_API_URL}/api/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        user_address: userAddress,
        country,
        password,
        recipient_id: props.charityID,
        challenge_id: props.challengeID,
        campaign_id: props.campaignID,
        number_of_units: props.totalTimes,
        cost_per_unit: props.perCost,
        confirmed_amount: props.totalAmount,
        opt_in_givebetter_comms: optInGiveBetter,
        opt_in_charity_comms: optInCharity,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false)
          throw new Error('Email already exists')
        } else {
          history.push('/activate')
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setError('emailExists', { message: 'Email already exists' })
      })
  }

  const onKeyEnter = (event: any) => {
    if (event.keyCode === 13) {
      handleSubmit(registration)
    }
  }

  const mouseEnter = (event: any) => {
    event.target.style.color = '#37769b'
  }

  const mouseLeave = (event: any) => {
    event.target.style.color = '#4990B4'
  }

  if (isLoading === true) {
    return (
      <div className="loader">
        <ReactLoading type={'bars'} color={'#67b4d6'} /> Loading
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(registration)}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className="Form"
          style={{
            display: 'grad',
            textAlign: 'left',
            maxWidth: '453px',
            gridGap: '10px',
          }}
        >
          <br />
          <div className="name-input" style={{ display: 'flex' }}>
            <input
              {...register('firstname')}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name*"
              className="input"
              value={firstName}
              type="text"
            />
            <p style={{ margin: '0' }}>{errors.firstname?.message}</p>
            <div style={{ marginRight: '1em' }}></div>
            <input
              {...register('lastname')}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last name*"
              className="input"
              value={lastName}
              type="text"
            />
            <p style={{ margin: '0' }}>{errors.lastname?.message}</p>
          </div>
          <div className="address-input">
            <input
              {...register('address')}
              style={{ height: '50px' }}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              className="input"
              value={userAddress}
              type="text"
            />
            <p style={{ margin: '0' }}>{errors.address?.message}</p>
            <select
              {...register('Country')}
              className="drop-down-button"
              defaultValue={'DEFAULT'}
              onChange={userCountry}
            >
              <option value="DEFAULT" disabled>
                Please select a country*
              </option>
              <option value="New Zealand">New Zealand</option>
              <option value="United Kingdom">United Kingdom</option>
            </select>
            <p style={{ margin: '0' }}>{errors.Country?.message}</p>
            {/* </div> */}
          </div>

          <div className="input-field" style={{ display: 'flex', flexFlow: 'column' }}>
            <input
              {...register('email')}
              style={{ height: '50px' }}
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{ margin: '0' }}>{errors.email?.message || errors.emailExists?.message}</p>
          </div>
          <div className="input-field" style={{ display: 'flex', flexFlow: 'column' }}>
            <input
              {...register('password')}
              style={{ height: '50px' }}
              placeholder="Password*"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p style={{ margin: '0' }}>{errors.password?.message}</p>
          </div>
          <div className="input-field" style={{ display: 'flex', flexFlow: 'column' }}>
            <input
              {...register('passwordConfirmation')}
              style={{ height: '50px' }}
              placeholder="Confirm Password*"
              type="password"
              onKeyDown={onKeyEnter}
            />
            <p style={{ margin: '0' }}>{errors.passwordConfirmation?.message}</p>
          </div>
          <div className="terms-wrapper" style={{ marginTop: '1em' }}>
            <input
              {...register('terms')}
              className="regCheckbox"
              type="checkBox"
              name="terms"
              onKeyDown={onKeyEnter}
            />
            <div style={{ flex: '1' }}>
              I am happy for Give Better to contact me about the challenge, and for my details to be
              shared with my chosen charity so they can send me a receipt*
            </div>
          </div>
          <p style={{ margin: '0' }}>{errors.terms?.message}</p>
          <div className="terms-wrapper">
            <input
              {...register('optInGiveBetter')}
              type="checkBox"
              className="regCheckbox"
              name="optInGiveBetter"
              onChange={(e) => setOptInGiveBetter(e.target.checked)}
              onKeyDown={onKeyEnter}
            />
            <div style={{ flex: '1' }}>I’d like to hear more from Give Better</div>
          </div>
          <div className="terms-wrapper">
            <input
              {...register('optInCharity')}
              className="regCheckbox"
              type="checkBox"
              name="optInCharity"
              onChange={(e) => {
                setOptInCharity(e.target.checked)
              }}
              onKeyDown={onKeyEnter}
            />
            <div style={{ flex: '1' }}>I’d like to hear more from my chosen charity</div>
          </div>
          <p className="privacy-link">
            <Link
              to="/privacy-policy"
              target="_blank"
              style={{ color: '#88BED6' }}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
            >
              See full privacy
            </Link>
          </p>
          <div className="login-register-button">
            <Link
              to="/login"
              style={{ color: '#4990B4' }}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
            >
              Already have an account? Click here to login
            </Link>
            <Link
              to="/activate"
              style={{ color: '#4990B4', marginTop: '8px' }}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
            >
              Need to activate your account? Click here
            </Link>
            <Link
              to="/password-recovery"
              style={{ color: '#4990B4', marginTop: '8px' }}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
            >
              Forget your password? Click here
            </Link>
          </div>
          <div className="registerbutton">
            <Button
              id="regibutton"
              className="nextbutton"
              type="submit"
              disabled={isSubmitting}
              onKeyDown={onKeyEnter}
            >
              Register
              <img style={{ paddingLeft: '5%' }} src={arrow2} alt="arrow2"></img>
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
