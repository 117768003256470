import { useEffect, useState } from 'react'

export default function TakeawayCalculator(props: {
  itemChosen: string
  totalAmount: Number
  perCost: Number
  weeklyAmount: Number
  back: Number
  remainingWeek: number
}) {
  const [perCost, setPerCost] = useState(0)
  const [weeklyCost, setWeeklyCost] = useState(0)
  const [total, setTotal] = useState(0)
  const [frequency, setFrequency] = useState('weekly')

  const weeklyChange = (event: any) => {
    if (event.target.value === 'monthly') {
      setFrequency(event.target.value)
      PubSub.publish('total', (perCost / 4) * props.remainingWeek)
      PubSub.publish('weeklyAmount', total / props.remainingWeek)
    }
    if (event.target.value === 'weekly') {
      setFrequency(event.target.value)
      PubSub.publish('total', perCost * props.remainingWeek)
      PubSub.publish('weeklyAmount', perCost)
    }
  }

  useEffect(() => {
    if (frequency === 'monthly') {
      setTotal((perCost / 4) * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('times', props.remainingWeek)
      PubSub.publish('total', total)
      PubSub.publish('perCost', perCost)
      PubSub.publish('weeklyAmount', weeklyCost)
    } else if (frequency === 'weekly') {
      setTotal(perCost * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('times', props.remainingWeek)
      PubSub.publish('total', total)
      PubSub.publish('perCost', perCost)
      PubSub.publish('weeklyAmount', weeklyCost)
    }
  }, [perCost, total, weeklyCost])

  const updateCost = (event: any) => {
    let value = event.target.value * 1
    if (frequency === 'monthly') {
      setPerCost(value)
    } else if (frequency === 'weekly') {
      setPerCost(value)
    } else {
      PubSub.publish('total', 0)
    }
  }

  return (
    <>
      <div className="calculator-body">
        <h2>Your spending</h2>
        <div className="times-wrapper" style={{ paddingBottom: '1em' }}>
          <span>How much do you spend on {props.itemChosen}?</span>
        </div>
        <div className="calculator-radio-wrapper" style={{ marginBottom: '0' }}>
          <input
            className="radio"
            type="radio"
            value="weekly"
            name="spending"
            onChange={weeklyChange}
            defaultChecked
          />
          &nbsp;Weekly&nbsp;&nbsp;
          <input
            className="radio"
            type="radio"
            value="monthly"
            name="spending"
            onChange={weeklyChange}
          />
          &nbsp;Monthly
        </div>
        <input
          className="text-box"
          type="text"
          name="how much"
          onChange={updateCost}
          placeholder={props.back === 0 ? 'e.g. 0.00' : String(perCost)}
          pattern="^\d+(\.)?(\d)*\d*$"
        />
      </div>
    </>
  )
}
