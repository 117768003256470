import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactLoading from 'react-loading'

import { resetPasswordSchema } from '../../components/Authorization/YupSchema'
import { useAuth } from '../../hooks/useAuth'
import '../../css/authorization.css'

export const ResetPage = () => {
  const auth = useAuth()
  const history = useHistory()
  const [resetToken, setResetToken] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [isSuccessfullySubmitted, setIsSuccessfullySubmit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  })

  const reset = async () => {
    setIsLoading(true)

    await fetch(`${process.env.REACT_APP_API_URL}/api/auth/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reset_token: resetToken,
        new_password: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Invalid reset token')
        } else {
          setTimeout(() => {
            setIsSuccessfullySubmit(true)
          }, 10)
          setResetToken('')
          setPassword('')
          setPasswordConfirmation('')
          setTimeout(() => {
            history.push('/login')
          }, 1500)
        }
      })
      .catch((error) => {
        setError('tokenError', { message: 'Not a valid token' })
        setIsLoading(false)
      })
      .finally(() => {
        setIsSuccessfullySubmit(false)
      })
  }

  if (auth.isAuthenticated) {
    history.push('/')
    return <div>Already logged in. Redirecting you to the home page...</div>
  }

  const onKeyEnter = (event: any) => {
    if (event.keyCode === 13) {
      handleSubmit(reset)
    }
  }

  if (isLoading === true) {
    return (
      <div className="loader">
        <ReactLoading type={'bars'} color={'#67b4d6'} /> Loading
      </div>
    )
  }

  return (
    <div className="Form-wrapper" style={{ marginBottom: '8.3em' }}>
      <div className="Form" style={{ textAlign: 'left' }}>
        <h1>Reset Password</h1>
        <br />
        <form onSubmit={handleSubmit(reset)}>
          <label>Reset Token</label>
          <input
            {...register('resetToken')}
            type="text"
            name="resetToken"
            placeholder="Reset Token"
            value={resetToken}
            onChange={(event) => setResetToken(event.target.value)}
          />
          <p>{errors.resetToken?.message || errors.tokenError?.message}</p>
          <label>New Password</label>
          <input
            {...register('password')}
            type="password"
            name="password"
            placeholder="New Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <p>{errors.password?.message}</p>
          <label>Confirm Password</label>
          <input
            {...register('passwordConfirmation')}
            type="password"
            name="passwordConfirmation"
            placeholder="Confirm New Password"
            value={passwordConfirmation}
            onKeyDown={onKeyEnter}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
          />
          <p>{errors.passwordConfirmation?.message}</p>
          <div className="button-wrapper">
            <Button
              style={{ marginTop: '0.5em' }}
              type="submit"
              disabled={isSubmitting || isSuccessfullySubmitted}
              onKeyDown={onKeyEnter}
            >
              Reset Password
            </Button>
            {isSuccessfullySubmitted && (
              <div className="submitMsg">
                <span>
                  <br />
                  You have successfully changed your password.
                  <br />
                  You will be redirected to the login page...
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
