import InvalidPage from '../images/404_message.svg'

export const NotFound = () => {
  return (
    <div className="error_page">
      <h1>Oops...</h1>
      <img src={InvalidPage} alt="404" />
    </div>
  )
}
