export default function SingleDollar() {
  return (
    <svg
      width="120"
      height="94"
      viewBox="0 0 120 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="dollars">
        <g id="dollar6" style={{ animationDelay: '5s' }}>
          <path
            d="M99 63.022C99.152 64.93 100.66 66.416 103.364 66.592V68H104.564V66.582C107.364 66.386 109 64.89 109 62.718C109 60.744 107.748 59.726 105.51 59.198L104.564 58.974V55.14C105.764 55.276 106.528 55.932 106.712 56.84H108.816C108.664 55.002 107.088 53.564 104.564 53.408V52H103.364V53.438C100.974 53.672 99.344 55.11 99.344 57.144C99.344 58.944 100.556 60.088 102.57 60.558L103.364 60.754V64.822C102.134 64.636 101.32 63.962 101.136 63.022H99ZM103.354 58.69C102.174 58.416 101.534 57.858 101.534 57.018C101.534 56.078 102.224 55.374 103.364 55.168V58.688H103.354V58.69ZM104.738 61.076C106.172 61.408 106.834 61.946 106.834 62.896C106.834 63.98 106.01 64.724 104.564 64.86V61.036L104.738 61.076Z"
            fill="#CFB942"
          />
          <path
            d="M93.747 70.253C96.4662 72.9723 100.154 74.5 104 74.5C107.846 74.5 111.534 72.9723 114.253 70.253C116.972 67.5338 118.5 63.8456 118.5 60C118.5 56.1544 116.972 52.4662 114.253 49.747C111.534 47.0277 107.846 45.5 104 45.5C100.154 45.5 96.4662 47.0277 93.747 49.747C91.0277 52.4662 89.5 56.1544 89.5 60C89.5 63.8456 91.0277 67.5338 93.747 70.253ZM114.96 70.9602C112.053 73.867 108.111 75.5 104 75.5C99.8891 75.5 95.9467 73.867 93.0398 70.9602C90.133 68.0533 88.5 64.1109 88.5 60C88.5 55.8891 90.133 51.9467 93.0398 49.0398C95.9467 46.133 99.8891 44.5 104 44.5C108.111 44.5 112.053 46.133 114.96 49.0398C117.867 51.9467 119.5 55.8891 119.5 60C119.5 64.1109 117.867 68.0533 114.96 70.9602Z"
            fill="#CFB942"
            stroke="#DFB82C"
          />
          <path
            d="M104 71C101.083 71 98.2847 69.8411 96.2218 67.7782C94.1589 65.7153 93 62.9174 93 60C93 57.0826 94.1589 54.2847 96.2218 52.2218C98.2847 50.1589 101.083 49 104 49C106.917 49 109.715 50.1589 111.778 52.2218C113.841 54.2847 115 57.0826 115 60C115 62.9174 113.841 65.7153 111.778 67.7782C109.715 69.8411 106.917 71 104 71ZM104 72C105.576 72 107.136 71.6896 108.592 71.0866C110.048 70.4835 111.371 69.5996 112.485 68.4853C113.6 67.371 114.483 66.0481 115.087 64.5922C115.69 63.1363 116 61.5759 116 60C116 58.4241 115.69 56.8637 115.087 55.4078C114.483 53.9519 113.6 52.629 112.485 51.5147C111.371 50.4004 110.048 49.5165 108.592 48.9134C107.136 48.3104 105.576 48 104 48C100.817 48 97.7652 49.2643 95.5147 51.5147C93.2643 53.7652 92 56.8174 92 60C92 63.1826 93.2643 66.2348 95.5147 68.4853C97.7652 70.7357 100.817 72 104 72Z"
            fill="#CFB942"
          />
        </g>
        <g id="dollar5" style={{ animationDelay: '4s' }}>
          <path
            d="M55 63.022C55.152 64.93 56.66 66.416 59.364 66.592V68H60.564V66.582C63.364 66.386 65 64.89 65 62.718C65 60.744 63.748 59.726 61.51 59.198L60.564 58.974V55.14C61.764 55.276 62.528 55.932 62.712 56.84H64.816C64.664 55.002 63.088 53.564 60.564 53.408V52H59.364V53.438C56.974 53.672 55.344 55.11 55.344 57.144C55.344 58.944 56.556 60.088 58.57 60.558L59.364 60.754V64.822C58.134 64.636 57.32 63.962 57.136 63.022H55ZM59.354 58.69C58.174 58.416 57.534 57.858 57.534 57.018C57.534 56.078 58.224 55.374 59.364 55.168V58.688H59.354V58.69ZM60.738 61.076C62.172 61.408 62.834 61.946 62.834 62.896C62.834 63.98 62.01 64.724 60.564 64.86V61.036L60.738 61.076Z"
            fill="#CFB942"
          />
          <path
            d="M49.747 70.253C52.4662 72.9723 56.1544 74.5 60 74.5C63.8456 74.5 67.5338 72.9723 70.253 70.253C72.9723 67.5338 74.5 63.8456 74.5 60C74.5 56.1544 72.9723 52.4662 70.253 49.747C67.5338 47.0277 63.8456 45.5 60 45.5C56.1544 45.5 52.4662 47.0277 49.747 49.747C47.0277 52.4662 45.5 56.1544 45.5 60C45.5 63.8456 47.0277 67.5338 49.747 70.253ZM70.9602 70.9602C68.0533 73.867 64.1109 75.5 60 75.5C55.8891 75.5 51.9467 73.867 49.0398 70.9602C46.133 68.0533 44.5 64.1109 44.5 60C44.5 55.8891 46.133 51.9467 49.0398 49.0398C51.9467 46.133 55.8891 44.5 60 44.5C64.1109 44.5 68.0533 46.133 70.9602 49.0398C73.867 51.9467 75.5 55.8891 75.5 60C75.5 64.1109 73.867 68.0533 70.9602 70.9602Z"
            fill="#CFB942"
            stroke="#DFB82C"
          />
          <path
            d="M60 71C57.0826 71 54.2847 69.8411 52.2218 67.7782C50.1589 65.7153 49 62.9174 49 60C49 57.0826 50.1589 54.2847 52.2218 52.2218C54.2847 50.1589 57.0826 49 60 49C62.9174 49 65.7153 50.1589 67.7782 52.2218C69.8411 54.2847 71 57.0826 71 60C71 62.9174 69.8411 65.7153 67.7782 67.7782C65.7153 69.8411 62.9174 71 60 71ZM60 72C61.5759 72 63.1363 71.6896 64.5922 71.0866C66.0481 70.4835 67.371 69.5996 68.4853 68.4853C69.5996 67.371 70.4835 66.0481 71.0866 64.5922C71.6896 63.1363 72 61.5759 72 60C72 58.4241 71.6896 56.8637 71.0866 55.4078C70.4835 53.9519 69.5996 52.629 68.4853 51.5147C67.371 50.4004 66.0481 49.5165 64.5922 48.9134C63.1363 48.3104 61.5759 48 60 48C56.8174 48 53.7652 49.2643 51.5147 51.5147C49.2643 53.7652 48 56.8174 48 60C48 63.1826 49.2643 66.2348 51.5147 68.4853C53.7652 70.7357 56.8174 72 60 72Z"
            fill="#CFB942"
          />
        </g>
        <g id="dollar4" style={{ animationDelay: '3s' }}>
          <path
            d="M11 63.022C11.152 64.93 12.66 66.416 15.364 66.592V68H16.564V66.582C19.364 66.386 21 64.89 21 62.718C21 60.744 19.748 59.726 17.51 59.198L16.564 58.974V55.14C17.764 55.276 18.528 55.932 18.712 56.84H20.816C20.664 55.002 19.088 53.564 16.564 53.408V52H15.364V53.438C12.974 53.672 11.344 55.11 11.344 57.144C11.344 58.944 12.556 60.088 14.57 60.558L15.364 60.754V64.822C14.134 64.636 13.32 63.962 13.136 63.022H11ZM15.354 58.69C14.174 58.416 13.534 57.858 13.534 57.018C13.534 56.078 14.224 55.374 15.364 55.168V58.688H15.354V58.69ZM16.738 61.076C18.172 61.408 18.834 61.946 18.834 62.896C18.834 63.98 18.01 64.724 16.564 64.86V61.036L16.738 61.076Z"
            fill="#CFB942"
          />
          <path
            d="M5.74695 70.253C8.46623 72.9723 12.1544 74.5 16 74.5C19.8456 74.5 23.5338 72.9723 26.253 70.253C28.9723 67.5338 30.5 63.8456 30.5 60C30.5 56.1544 28.9723 52.4662 26.253 49.747C23.5338 47.0277 19.8456 45.5 16 45.5C12.1544 45.5 8.46623 47.0277 5.74695 49.747C3.02767 52.4662 1.5 56.1544 1.5 60C1.5 63.8456 3.02767 67.5338 5.74695 70.253ZM26.9602 70.9602C24.0533 73.867 20.1109 75.5 16 75.5C11.8891 75.5 7.94666 73.867 5.03984 70.9602C2.13303 68.0533 0.5 64.1109 0.5 60C0.5 55.8891 2.13303 51.9467 5.03984 49.0398C7.94666 46.133 11.8891 44.5 16 44.5C20.1109 44.5 24.0533 46.133 26.9602 49.0398C29.867 51.9467 31.5 55.8891 31.5 60C31.5 64.1109 29.867 68.0533 26.9602 70.9602Z"
            fill="#CFB942"
            stroke="#DFB82C"
          />
          <path
            d="M16 71C13.0826 71 10.2847 69.8411 8.22183 67.7782C6.15893 65.7153 5 62.9174 5 60C5 57.0826 6.15893 54.2847 8.22183 52.2218C10.2847 50.1589 13.0826 49 16 49C18.9174 49 21.7153 50.1589 23.7782 52.2218C25.8411 54.2847 27 57.0826 27 60C27 62.9174 25.8411 65.7153 23.7782 67.7782C21.7153 69.8411 18.9174 71 16 71ZM16 72C17.5759 72 19.1363 71.6896 20.5922 71.0866C22.0481 70.4835 23.371 69.5996 24.4853 68.4853C25.5996 67.371 26.4835 66.0481 27.0866 64.5922C27.6896 63.1363 28 61.5759 28 60C28 58.4241 27.6896 56.8637 27.0866 55.4078C26.4835 53.9519 25.5996 52.629 24.4853 51.5147C23.371 50.4004 22.0481 49.5165 20.5922 48.9134C19.1363 48.3104 17.5759 48 16 48C12.8174 48 9.76515 49.2643 7.51472 51.5147C5.26428 53.7652 4 56.8174 4 60C4 63.1826 5.26428 66.2348 7.51472 68.4853C9.76515 70.7357 12.8174 72 16 72Z"
            fill="#CFB942"
          />
        </g>
        <g id="dollar3" style={{ animationDelay: '2s' }}>
          <path
            d="M99 19.022C99.152 20.93 100.66 22.416 103.364 22.592V24H104.564V22.582C107.364 22.386 109 20.89 109 18.718C109 16.744 107.748 15.726 105.51 15.198L104.564 14.974V11.14C105.764 11.276 106.528 11.932 106.712 12.84H108.816C108.664 11.002 107.088 9.564 104.564 9.408V8H103.364V9.438C100.974 9.672 99.344 11.11 99.344 13.144C99.344 14.944 100.556 16.088 102.57 16.558L103.364 16.754V20.822C102.134 20.636 101.32 19.962 101.136 19.022H99ZM103.354 14.69C102.174 14.416 101.534 13.858 101.534 13.018C101.534 12.078 102.224 11.374 103.364 11.168V14.688H103.354V14.69ZM104.738 17.076C106.172 17.408 106.834 17.946 106.834 18.896C106.834 19.98 106.01 20.724 104.564 20.86V17.036L104.738 17.076Z"
            fill="#CFB942"
          />
          <path
            d="M93.747 26.253C96.4662 28.9723 100.154 30.5 104 30.5C107.846 30.5 111.534 28.9723 114.253 26.253C116.972 23.5338 118.5 19.8456 118.5 16C118.5 12.1544 116.972 8.46623 114.253 5.74695C111.534 3.02767 107.846 1.5 104 1.5C100.154 1.5 96.4662 3.02767 93.747 5.74695C91.0277 8.46623 89.5 12.1544 89.5 16C89.5 19.8456 91.0277 23.5338 93.747 26.253ZM114.96 26.9602C112.053 29.867 108.111 31.5 104 31.5C99.8891 31.5 95.9467 29.867 93.0398 26.9602C90.133 24.0533 88.5 20.1109 88.5 16C88.5 11.8891 90.133 7.94666 93.0398 5.03984C95.9467 2.13303 99.8891 0.5 104 0.5C108.111 0.5 112.053 2.13303 114.96 5.03984C117.867 7.94666 119.5 11.8891 119.5 16C119.5 20.1109 117.867 24.0533 114.96 26.9602Z"
            fill="#CFB942"
            stroke="#DFB82C"
          />
          <path
            d="M104 27C101.083 27 98.2847 25.8411 96.2218 23.7782C94.1589 21.7153 93 18.9174 93 16C93 13.0826 94.1589 10.2847 96.2218 8.22183C98.2847 6.15893 101.083 5 104 5C106.917 5 109.715 6.15893 111.778 8.22183C113.841 10.2847 115 13.0826 115 16C115 18.9174 113.841 21.7153 111.778 23.7782C109.715 25.8411 106.917 27 104 27ZM104 28C105.576 28 107.136 27.6896 108.592 27.0866C110.048 26.4835 111.371 25.5996 112.485 24.4853C113.6 23.371 114.483 22.0481 115.087 20.5922C115.69 19.1363 116 17.5759 116 16C116 14.4241 115.69 12.8637 115.087 11.4078C114.483 9.95189 113.6 8.62902 112.485 7.51472C111.371 6.40042 110.048 5.5165 108.592 4.91345C107.136 4.31039 105.576 4 104 4C100.817 4 97.7652 5.26428 95.5147 7.51472C93.2643 9.76515 92 12.8174 92 16C92 19.1826 93.2643 22.2348 95.5147 24.4853C97.7652 26.7357 100.817 28 104 28Z"
            fill="#CFB942"
          />
        </g>
        <g id="dollar2" style={{ animationDelay: '1s' }}>
          <path
            d="M55 19.022C55.152 20.93 56.66 22.416 59.364 22.592V24H60.564V22.582C63.364 22.386 65 20.89 65 18.718C65 16.744 63.748 15.726 61.51 15.198L60.564 14.974V11.14C61.764 11.276 62.528 11.932 62.712 12.84H64.816C64.664 11.002 63.088 9.564 60.564 9.408V8H59.364V9.438C56.974 9.672 55.344 11.11 55.344 13.144C55.344 14.944 56.556 16.088 58.57 16.558L59.364 16.754V20.822C58.134 20.636 57.32 19.962 57.136 19.022H55ZM59.354 14.69C58.174 14.416 57.534 13.858 57.534 13.018C57.534 12.078 58.224 11.374 59.364 11.168V14.688H59.354V14.69ZM60.738 17.076C62.172 17.408 62.834 17.946 62.834 18.896C62.834 19.98 62.01 20.724 60.564 20.86V17.036L60.738 17.076Z"
            fill="#CFB942"
          />
          <path
            d="M49.747 26.253C52.4662 28.9723 56.1544 30.5 60 30.5C63.8456 30.5 67.5338 28.9723 70.253 26.253C72.9723 23.5338 74.5 19.8456 74.5 16C74.5 12.1544 72.9723 8.46623 70.253 5.74695C67.5338 3.02767 63.8456 1.5 60 1.5C56.1544 1.5 52.4662 3.02767 49.747 5.74695C47.0277 8.46623 45.5 12.1544 45.5 16C45.5 19.8456 47.0277 23.5338 49.747 26.253ZM70.9602 26.9602C68.0533 29.867 64.1109 31.5 60 31.5C55.8891 31.5 51.9467 29.867 49.0398 26.9602C46.133 24.0533 44.5 20.1109 44.5 16C44.5 11.8891 46.133 7.94666 49.0398 5.03984C51.9467 2.13303 55.8891 0.5 60 0.5C64.1109 0.5 68.0533 2.13303 70.9602 5.03984C73.867 7.94666 75.5 11.8891 75.5 16C75.5 20.1109 73.867 24.0533 70.9602 26.9602Z"
            fill="#CFB942"
            stroke="#DFB82C"
          />
          <path
            d="M60 27C57.0826 27 54.2847 25.8411 52.2218 23.7782C50.1589 21.7153 49 18.9174 49 16C49 13.0826 50.1589 10.2847 52.2218 8.22183C54.2847 6.15893 57.0826 5 60 5C62.9174 5 65.7153 6.15893 67.7782 8.22183C69.8411 10.2847 71 13.0826 71 16C71 18.9174 69.8411 21.7153 67.7782 23.7782C65.7153 25.8411 62.9174 27 60 27ZM60 28C61.5759 28 63.1363 27.6896 64.5922 27.0866C66.0481 26.4835 67.371 25.5996 68.4853 24.4853C69.5996 23.371 70.4835 22.0481 71.0866 20.5922C71.6896 19.1363 72 17.5759 72 16C72 14.4241 71.6896 12.8637 71.0866 11.4078C70.4835 9.95189 69.5996 8.62902 68.4853 7.51472C67.371 6.40042 66.0481 5.5165 64.5922 4.91345C63.1363 4.31039 61.5759 4 60 4C56.8174 4 53.7652 5.26428 51.5147 7.51472C49.2643 9.76515 48 12.8174 48 16C48 19.1826 49.2643 22.2348 51.5147 24.4853C53.7652 26.7357 56.8174 28 60 28Z"
            fill="#CFB942"
          />
        </g>
        <g id="dollar1" style={{ animationDelay: '0s' }}>
          <path
            d="M11 19.022C11.152 20.93 12.66 22.416 15.364 22.592V24H16.564V22.582C19.364 22.386 21 20.89 21 18.718C21 16.744 19.748 15.726 17.51 15.198L16.564 14.974V11.14C17.764 11.276 18.528 11.932 18.712 12.84H20.816C20.664 11.002 19.088 9.564 16.564 9.408V8H15.364V9.438C12.974 9.672 11.344 11.11 11.344 13.144C11.344 14.944 12.556 16.088 14.57 16.558L15.364 16.754V20.822C14.134 20.636 13.32 19.962 13.136 19.022H11ZM15.354 14.69C14.174 14.416 13.534 13.858 13.534 13.018C13.534 12.078 14.224 11.374 15.364 11.168V14.688H15.354V14.69ZM16.738 17.076C18.172 17.408 18.834 17.946 18.834 18.896C18.834 19.98 18.01 20.724 16.564 20.86V17.036L16.738 17.076Z"
            fill="#CFB942"
          />
          <path
            d="M5.74695 26.253C8.46623 28.9723 12.1544 30.5 16 30.5C19.8456 30.5 23.5338 28.9723 26.253 26.253C28.9723 23.5338 30.5 19.8456 30.5 16C30.5 12.1544 28.9723 8.46623 26.253 5.74695C23.5338 3.02767 19.8456 1.5 16 1.5C12.1544 1.5 8.46623 3.02767 5.74695 5.74695C3.02767 8.46623 1.5 12.1544 1.5 16C1.5 19.8456 3.02767 23.5338 5.74695 26.253ZM26.9602 26.9602C24.0533 29.867 20.1109 31.5 16 31.5C11.8891 31.5 7.94666 29.867 5.03984 26.9602C2.13303 24.0533 0.5 20.1109 0.5 16C0.5 11.8891 2.13303 7.94666 5.03984 5.03984C7.94666 2.13303 11.8891 0.5 16 0.5C20.1109 0.5 24.0533 2.13303 26.9602 5.03984C29.867 7.94666 31.5 11.8891 31.5 16C31.5 20.1109 29.867 24.0533 26.9602 26.9602Z"
            fill="#CFB942"
            stroke="#DFB82C"
          />
          <path
            d="M16 27C13.0826 27 10.2847 25.8411 8.22183 23.7782C6.15893 21.7153 5 18.9174 5 16C5 13.0826 6.15893 10.2847 8.22183 8.22183C10.2847 6.15893 13.0826 5 16 5C18.9174 5 21.7153 6.15893 23.7782 8.22183C25.8411 10.2847 27 13.0826 27 16C27 18.9174 25.8411 21.7153 23.7782 23.7782C21.7153 25.8411 18.9174 27 16 27ZM16 28C17.5759 28 19.1363 27.6896 20.5922 27.0866C22.0481 26.4835 23.371 25.5996 24.4853 24.4853C25.5996 23.371 26.4835 22.0481 27.0866 20.5922C27.6896 19.1363 28 17.5759 28 16C28 14.4241 27.6896 12.8637 27.0866 11.4078C26.4835 9.95189 25.5996 8.62902 24.4853 7.51472C23.371 6.40042 22.0481 5.5165 20.5922 4.91345C19.1363 4.31039 17.5759 4 16 4C12.8174 4 9.76515 5.26428 7.51472 7.51472C5.26428 9.76515 4 12.8174 4 16C4 19.1826 5.26428 22.2348 7.51472 24.4853C9.76515 26.7357 12.8174 28 16 28Z"
            fill="#CFB942"
          />
        </g>
      </g>
    </svg>
  )
}
