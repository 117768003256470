import '../../css/stepper.css'

import * as React from 'react'
import { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import PubSub from 'pubsub-js'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import { MobileStepper } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

import arrow from '../../images/stepperarrow.svg'
import arrow2 from '../../images/stepperarrow2.svg'
import { challengeStatus, setChallengeStatus, ChooseChallenge } from './ChooseChallenge'
import CalculateImpact from './CalculateImpact'
import { status, setStatus, ChooseCause } from './ChooseCause'
import PaymentDetails from './PaymentDetails'
import { useAuth } from '../../hooks/useAuth'
import { RegistrationPage } from '../Authorization/RegistrationPage'
import { Country } from '../../types/enums'
import { useCampaign } from '../../hooks/campaign'
import { useRecipient } from '../../hooks/recipient'
import { useChallenges } from '../../hooks/challenge'
import ChooseCampaign, { campaignStatus, setCampaignStatus } from './ChooseCampaign'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

const steps = [
  'Choose campaign',
  'Choose challenges',
  'Calculate pledge amount',
  'Choose cause',
  'Payment and details',
]
const nextsteps = [
  'Choose campaign',
  'Join',
  'Calculate pledge amount',
  'Choose cause',
  'Payment details',
]
let back = 0

export default function HorizontalLinearStepper(props: {
  itemChosen: string
  charityID: Number
  challengeID: Number
  campaignID: Number
  totalAmount: Number
  totalTimes: Number
  parkingCost: Number
  weeklyAmount: Number
  perCost: Number
  country: any
  parkingChecked: boolean
}) {
  const auth = useAuth()
  const history = useHistory()
  let [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [pledgeStatus, setPledgeStatus] = useState(String)
  const [joinStatus, setJoinStatus] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const countryString = props.country === Country.NZ ? 'NZ' : 'UK'
  const currentCampaign = useCampaign()
  const recipients = useRecipient()
  const challenge = useChallenges()

  let isEmpty = false
  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const activeStepPath = () => {
    return steps[activeStep]
  }

  useEffect(() => {
    PubSub.subscribe('loading', (smg, item) => {
      setIsLoading(item)
    })
  }, [])

  useEffect(() => {
    const getInfo = async () => {
      currentCampaign.getCampaign(countryString)
      recipients.getRecipients(countryString)
      challenge.getChallenges(countryString)
      if (currentCampaign.remainingWeek == 0) {
        setJoinStatus(false)
      }
    }
    getInfo()
  }, [props.country])

  const checkPledgeStatus = useCallback(async () => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}/api/pledge/closest`, {
        headers: {
          Authorization: `${auth.accessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'No pledges!') {
            setPledgeStatus('Cancelled')
          } else if (data.message !== 'No pledges!') {
            setPledgeStatus(data.pledge_status)
          }
          if (data.pledge_status === 'Pending' && auth.isAuthenticated) {
            setActiveStep(3)
          } else if (data.pledge_status === 'Cancelled' && auth.isAuthenticated) {
            setActiveStep(0)
          }
        })
    } catch (e) {
      setActiveStep(0)
      console.log(e)
    }
  }, [auth.accessToken, auth.isAuthenticated])

  useEffect(() => {
    if (auth.isAuthenticated) {
      checkPledgeStatus()
    }
  }, [auth.isAuthenticated, checkPledgeStatus])

  const handleNext = async () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }
    if (
      props.totalAmount < 10 ||
      props.totalAmount.toString() === 'NaN' ||
      (props.itemChosen === 'Commutes' && props.parkingChecked === false && props.parkingCost === 0)
    ) {
      isEmpty = true
    } else {
      isEmpty = false
    }

    if (activeStep === 0 && !campaignStatus) {
      setActiveStep(0)
      setSkipped(newSkipped)
    } else if (activeStep === 1 && !challengeStatus) {
      setActiveStep((prevActiveStep) => prevActiveStep)
      setSkipped(newSkipped)
    } else if (activeStep === 2 && isEmpty) {
      setActiveStep((prevActiveStep) => prevActiveStep)
      setSkipped(newSkipped)
    } else if (activeStep === 3 && !status) {
      setActiveStep((prevActiveStep) => prevActiveStep)
      setSkipped(newSkipped)
    } else if (activeStep === 4 && auth.isAuthenticated === false) {
      setActiveStep((prevActiveStep) => prevActiveStep)
      setSkipped(newSkipped)
    } else if (
      (activeStep === 3 && pledgeStatus === 'Cancelled') ||
      (activeStep === 3 && pledgeStatus === '' && auth.isAuthenticated)
    ) {
      await fetch(`${process.env.REACT_APP_API_URL}/api/pledge`, {
        method: 'POST',
        headers: {
          Authorization: `${auth.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipient_id: props.charityID,
          challenge_id: props.challengeID,
          campaign_id: props.campaignID,
          number_of_units: props.totalTimes,
          cost_per_unit: props.perCost,
          confirmed_amount: props.totalAmount,
        }),
      }).catch((e) => {
        console.log(e)
      })
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setSkipped(newSkipped)
      history.push(`/challenge?step=${activeStep}`)
    }
  }

  const handleBack = () => {
    setStatus(false)
    setChallengeStatus(false)
    setCampaignStatus(false)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    back += 1
  }

  const handleReset = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/delete`, {
      method: 'DELETE',
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((e) => {
        console.log(e)
      })
    checkPledgeStatus()
    setActiveStep(0)
    PubSub.publish('total', 0)
    back = 0
  }

  const pages = (activeStep?: any) => {
    if (activeStep === 0) {
      return (
        <>
          <ChooseCampaign />
        </>
      )
    }
    if (activeStep === 1) {
      return (
        <>
          <ChooseChallenge
            country={props.country}
            challengeIDs={challenge.challengeIDs}
            challengesName={challenge.challengeNames}
            challengesDesc={challenge.challengeDesc}
            challengesHashtag={challenge.hashTag}
            challengesHashtagLink={challenge.hashTagLink}
            challengesIcon={challenge.challengeIcons}
          />
        </>
      )
    } else if (activeStep === 2) {
      return (
        <>
          <CalculateImpact
            itemChosen={props.itemChosen}
            totalAmount={props.totalAmount}
            totalTimes={props.totalTimes}
            parkingCost={props.parkingCost}
            perCost={props.perCost}
            weeklyAmount={props.weeklyAmount}
            back={back}
            country={props.country}
            parkingChecked={props.parkingChecked}
          />
        </>
      )
    } else if (activeStep === 3) {
      return (
        <>
          <ChooseCause
            totalAmount={props.totalAmount}
            country={props.country}
            charityIDs={recipients.recipientID}
            charityName={recipients.recipientNames}
            charityUnit={recipients.recipientImpactUnits}
            charityDesc={recipients.recipientDescriptions}
            charityLogo={recipients.recipientLogos}
            charityLink={recipients.recipientLink}
            charityImpactCost={recipients.recipientImpactCosts}
          />
        </>
      )
    } else {
      return <PaymentDetails country={props.country} />
    }
  }

  const nextButton = (activeStep: number) => {
    return (
      <Button
        className="nextbutton"
        onClick={handleNext}
        style={{
          textTransform: 'none',
          marginRight: activeStep === 0 ? '132px' : '0',
          width: activeStep === 0 ? '650px' : activeStep === 3 ? '330px' : '400px',
        }}
      >
        {activeStep === steps.length - 1 ? 'Finish' : nextsteps[activeStep + 1]}
        <img style={{ paddingLeft: '5%' }} src={arrow2} alt="arrow2"></img>
      </Button>
    )
  }

  const backButton = (activeStep: number) => {
    return (
      <Button
        className="backbutton"
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1 }}
        style={{
          textTransform: 'none',
          visibility: activeStep === 0 ? 'hidden' : 'visible',
          margin: '0 auto',
        }}
      >
        <img style={{ paddingRight: '20%' }} src={arrow} alt="arrow"></img>Back
      </Button>
    )
  }

  const paymentAvailable = (activeStep: any) => {
    if (isLoading === true) {
      activeStep = 0
    }
    return (
      <>
        <p
          className="p"
          style={{
            paddingTop: '30px',
            fontSize: '24px',
            fontWeight: '600',
            fontFamily: 'Inter',
            marginBottom: '0',
          }}
        >
          We'll use these details to manage your pledge and send you your receipts after each
          payment.
        </p>
        <Box sx={{ paddingBottom: '20px' }}>
          <RegistrationPage
            charityID={props.charityID}
            challengeID={props.challengeID}
            campaignID={props.campaignID}
            totalAmount={props.totalAmount}
            totalTimes={props.totalTimes}
            perCost={props.perCost}
          />
          <div>{backButton(activeStep)}</div>
        </Box>
      </>
    )
  }

  const handleRegistration = (activeStep?: any) => {
    if (activeStep === 4 && auth.isAuthenticated) {
      return (
        <>
          <div>{pages(activeStep)}</div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ flex: '1 1 auto' }} />
            <div className="cancelcontainer">
              <Button className="cancel" onClick={handleReset} style={{ textTransform: 'none' }}>
                Cancel
              </Button>
            </div>
          </Box>{' '}
        </>
      )
    } else {
      return <React.Fragment>{paymentAvailable(activeStep)}</React.Fragment>
    }
  }

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 14.6px)',
      right: 'calc(50% + 14.6px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        border: '5px solid #67B4D6',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        border: '5px solid #67B4D6',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      border: '5px solid #CDCDCD',
      borderRadius: 1,
    },
  }))

  if (pledgeStatus === 'Pending' && auth.isAuthenticated) {
    activeStep = 4
  }

  return (
    <Box>
      <h2 style={{ fontFamily: 'Inter' }}>Make your pledge to a charity</h2>
      {window.innerWidth > 650 ? (
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps} key={index * 2}>
                  {label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      ) : (
        <></>
      )}

      <React.Fragment></React.Fragment>
      {activeStep === steps.length - 1 ? (
        <React.Fragment>
          <div style={{ paddingBottom: '20px' }}>{handleRegistration(activeStep)}</div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {pledgeStatus === 'Pending' && auth.isAuthenticated ? (
            <>{handleRegistration(3)}</>
          ) : (
            <>
              {window.innerWidth > 650 ? (
                <>
                  <div>{pages(activeStep)}</div>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      pr: '20%',
                      pl: '20%',
                      pt: 2,
                      marginBottom: '80px',
                    }}
                  >
                    {backButton(activeStep)}
                    <Box sx={{ flex: '1 1 auto' }} />
                    {nextButton(activeStep)}
                  </Box>
                </>
              ) : (
                <>
                  <div>{pages(activeStep)}</div>
                  <MobileStepper
                    variant="dots"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    sx={{
                      maxWidth: 650,
                      flexGrow: 1,
                      justifyContent: 'space-evenly',
                      marginTop: 0,
                      paddingBottom: 8,
                    }}
                    nextButton={
                      <Button
                        className="stepper-button"
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === steps.length - 1}
                      >
                        Next
                        <KeyboardArrowRight />
                      </Button>
                    }
                    backButton={
                      <Button
                        className="stepper-button"
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        <KeyboardArrowLeft />
                        Back
                      </Button>
                    }
                  />
                </>
              )}
            </>
          )}
        </React.Fragment>
      )}
    </Box>
  )
}
