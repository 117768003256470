export default function Coffee() {
  return (
    <svg
      width="140"
      height="94"
      viewBox="0 0 140 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="coffees">
        <g id="coffee6" style={{ animationDelay: '5s' }}>
          <path
            d="M106.671 57.9587C106.971 57.4331 107.405 56.9962 107.928 56.6923C108.451 56.3884 109.046 56.2282 109.651 56.228H127.28C127.885 56.2282 128.48 56.3884 129.003 56.6923C129.527 56.9962 129.96 57.4331 130.261 57.9587L132.223 61.3924C133.336 63.3394 132.307 65.6898 130.352 66.3491C130.36 66.4899 130.359 66.6307 130.348 66.7732L128.877 87.376C128.815 88.2423 128.428 89.053 127.792 89.645C127.156 90.2369 126.32 90.566 125.452 90.5659H111.478C110.609 90.566 109.773 90.2369 109.137 89.645C108.502 89.053 108.114 88.2423 108.053 87.376L106.581 66.7732C106.571 66.6321 106.569 66.4905 106.576 66.3491C104.622 65.6898 103.594 63.3394 104.705 61.3924L106.669 57.9587H106.671ZM110.008 66.5294L110.376 71.6801H126.556L126.923 66.5294H110.008ZM111.112 81.9815L111.48 87.1322H125.452L125.819 81.9815H111.112ZM129.243 63.0956L127.28 59.6618H109.651L107.689 63.0956H129.243Z"
            fill="#8B702C"
          />
        </g>
        <g id="coffee5" style={{ animationDelay: '4s' }}>
          <path
            d="M57.7391 57.9587C58.0393 57.4331 58.4731 56.9962 58.9965 56.6923C59.5199 56.3884 60.1144 56.2282 60.7196 56.228H78.3487C78.954 56.2282 79.5484 56.3884 80.0718 56.6923C80.5953 56.9962 81.029 57.4331 81.3293 57.9587L83.2917 61.3924C84.4042 63.3394 83.3758 65.6898 81.4202 66.3491C81.4288 66.4899 81.4271 66.6307 81.4168 66.7732L79.9454 87.376C79.8838 88.2423 79.4961 89.053 78.8606 89.645C78.225 90.2369 77.3887 90.566 76.5202 90.5659H62.5464C61.6779 90.566 60.8417 90.2369 60.2061 89.645C59.5705 89.053 59.1829 88.2423 59.1212 87.376L57.6498 66.7732C57.6395 66.6321 57.6378 66.4905 57.6447 66.3491C55.6909 65.6898 54.6624 63.3394 55.7733 61.3924L57.7374 57.9587H57.7391ZM61.0768 66.5294L61.4442 71.6801H77.6242L77.9916 66.5294H61.0768ZM62.1807 81.9815L62.5481 87.1322H76.5202L76.8876 81.9815H62.1807ZM80.3111 63.0956L78.3487 59.6618H60.7196L58.7572 63.0956H80.3111Z"
            fill="#8B702C"
          />
        </g>
        <g id="coffee4" style={{ animationDelay: '3s' }}>
          <path
            d="M8.80772 57.9587C9.10792 57.4331 9.54171 56.9962 10.0651 56.6923C10.5885 56.3884 11.183 56.2282 11.7882 56.228H29.4173C30.0226 56.2282 30.617 56.3884 31.1404 56.6923C31.6639 56.9962 32.0977 57.4331 32.3979 57.9587L34.3603 61.3924C35.4728 63.3394 34.4444 65.6898 32.4889 66.3491C32.4974 66.4899 32.4957 66.6307 32.4854 66.7732L31.014 87.376C30.9524 88.2423 30.5647 89.053 29.9292 89.645C29.2936 90.2369 28.4573 90.566 27.5888 90.5659H13.615C12.7465 90.566 11.9103 90.2369 11.2747 89.645C10.6391 89.053 10.2515 88.2423 10.1898 87.376L8.71844 66.7732C8.70809 66.6321 8.70637 66.4905 8.71329 66.3491C6.75946 65.6898 5.73104 63.3394 6.84187 61.3924L8.806 57.9587H8.80772ZM12.1454 66.5294L12.5128 71.6801H28.6928L29.0602 66.5294H12.1454ZM13.2493 81.9815L13.6167 87.1322H27.5888L27.9562 81.9815H13.2493ZM31.3797 63.0956L29.4173 59.6618H11.7882L9.82584 63.0956H31.3797Z"
            fill="#8B702C"
          />
        </g>
        <g id="coffee3" style={{ animationDelay: '2s' }}>
          <path
            d="M106.671 5.16422C106.971 4.63867 107.405 4.2018 107.928 3.89788C108.451 3.59396 109.046 3.43379 109.651 3.43359H127.28C127.885 3.43379 128.48 3.59396 129.003 3.89788C129.527 4.2018 129.96 4.63867 130.261 5.16422L132.223 8.59801C133.336 10.545 132.307 12.8954 130.352 13.5547C130.36 13.6955 130.359 13.8363 130.348 13.9788L128.877 34.5815C128.815 35.4478 128.428 36.2586 127.792 36.8505C127.156 37.4424 126.32 37.7715 125.452 37.7715H111.478C110.609 37.7715 109.773 37.4424 109.137 36.8505C108.502 36.2586 108.114 35.4478 108.053 34.5815L106.581 13.9788C106.571 13.8376 106.569 13.696 106.576 13.5547C104.622 12.8954 103.594 10.545 104.705 8.59801L106.669 5.16422H106.671ZM110.008 13.735L110.376 18.8857H126.556L126.923 13.735H110.008ZM111.112 29.187L111.48 34.3377H125.452L125.819 29.187H111.112ZM129.243 10.3012L127.28 6.86738H109.651L107.689 10.3012H129.243Z"
            fill="#8B702C"
          />
        </g>
        <g id="coffee2" style={{ animationDelay: '1s' }}>
          <path
            d="M57.7394 5.16422C58.0396 4.63867 58.4734 4.2018 58.9968 3.89788C59.5202 3.59396 60.1146 3.43379 60.7199 3.43359H78.349C78.9542 3.43379 79.5487 3.59396 80.0721 3.89788C80.5955 4.2018 81.0293 4.63867 81.3295 5.16422L83.2919 8.59801C84.4045 10.545 83.376 12.8954 81.4205 13.5547C81.4291 13.6955 81.4274 13.8363 81.4171 13.9788L79.9457 34.5815C79.884 35.4478 79.4964 36.2586 78.8608 36.8505C78.2252 37.4424 77.389 37.7715 76.5205 37.7715H62.5467C61.6782 37.7715 60.8419 37.4424 60.2063 36.8505C59.5708 36.2586 59.1831 35.4478 59.1215 34.5815L57.6501 13.9788C57.6397 13.8376 57.638 13.696 57.6449 13.5547C55.6911 12.8954 54.6627 10.545 55.7735 8.59801L57.7376 5.16422H57.7394ZM61.077 13.735L61.4444 18.8857H77.6244L77.9919 13.735H61.077ZM62.181 29.187L62.5484 34.3377H76.5205L76.8879 29.187H62.181ZM80.3114 10.3012L78.349 6.86738H60.7199L58.7575 10.3012H80.3114Z"
            fill="#8B702C"
          />
        </g>
        <g id="coffee1" style={{ animationDelay: '0s' }}>
          <path
            d="M8.80772 5.16422C9.10792 4.63867 9.54171 4.2018 10.0651 3.89788C10.5885 3.59396 11.183 3.43379 11.7882 3.43359H29.4173C30.0226 3.43379 30.617 3.59396 31.1404 3.89788C31.6639 4.2018 32.0977 4.63867 32.3979 5.16422L34.3603 8.59801C35.4728 10.545 34.4444 12.8954 32.4889 13.5547C32.4974 13.6955 32.4957 13.8363 32.4854 13.9788L31.014 34.5815C30.9524 35.4478 30.5647 36.2586 29.9292 36.8505C29.2936 37.4424 28.4573 37.7715 27.5888 37.7715H13.615C12.7465 37.7715 11.9103 37.4424 11.2747 36.8505C10.6391 36.2586 10.2515 35.4478 10.1898 34.5815L8.71844 13.9788C8.70809 13.8376 8.70637 13.696 8.71329 13.5547C6.75946 12.8954 5.73104 10.545 6.84187 8.59801L8.806 5.16422H8.80772ZM12.1454 13.735L12.5128 18.8857H28.6928L29.0602 13.735H12.1454ZM13.2493 29.187L13.6167 34.3377H27.5888L27.9562 29.187H13.2493ZM31.3797 10.3012L29.4173 6.86738H11.7882L9.82584 10.3012H31.3797Z"
            fill="#8B702C"
          />
        </g>
      </g>
    </svg>
  )
}
