import '../../css/onboarding.css'
import PaymentMethod from './PaymentMethod'

export default function PaymentDetails(props: { country: any }) {
  return (
    <div className="payment-wrapper">
      <div className="paymentdetail-outer-wrapper">
        <div className="paymentelement">
          <PaymentMethod country={props.country} />
        </div>
      </div>
    </div>
  )
}
