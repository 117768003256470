import CoffeeCalculator from './CoffeeCalculator'
import TakeawayCalculator from './TakeawayCalculator'
import GymAndStreamCalculator from './GymAndStreamCalculator'
import CommuteCalculator from './CommuteCalculator'
import { Country } from '../../types/enums'
import { useCampaign } from '../../hooks/campaign'
import { useEffect } from 'react'

export default function Calculator(props: {
  itemChosen: string
  totalAmount: Number
  totalTimes: Number
  parkingCost: Number
  perCost: Number
  weeklyAmount: Number
  back: Number
  country: any
  parkingChecked: boolean
}) {
  const currentCampaign = useCampaign()
  const countryString = props.country === Country.NZ ? 'NZ' : 'UK'
  useEffect(() => {
    const getInfo = async () => {
      currentCampaign.getCampaign(countryString)
    }
    getInfo()
  }, [props.country])

  const calculatorSelector = (itemChosen: string) => {
    if (itemChosen === 'Coffees') {
      return (
        <CoffeeCalculator
          itemChosen={props.itemChosen}
          totalAmount={props.totalAmount}
          totalTimes={props.totalTimes}
          perCost={props.perCost}
          weeklyAmount={props.weeklyAmount}
          back={props.back}
          remainingWeek={currentCampaign.remainingWeek}
        />
      )
    } else if (itemChosen === 'Food deliveries' || itemChosen === 'Lunches') {
      return (
        <TakeawayCalculator
          itemChosen={props.itemChosen}
          totalAmount={props.totalAmount}
          perCost={props.perCost}
          weeklyAmount={props.weeklyAmount}
          back={props.back}
          remainingWeek={currentCampaign.remainingWeek}
        />
      )
    } else if (itemChosen === 'Gym membership' || itemChosen === 'Streaming services') {
      return (
        <GymAndStreamCalculator
          itemChosen={props.itemChosen}
          totalAmount={props.totalAmount}
          perCost={props.perCost}
          weeklyAmount={props.weeklyAmount}
          back={props.back}
          remainingWeek={currentCampaign.remainingWeek}
        />
      )
    } else if (itemChosen === 'Commutes') {
      return (
        <CommuteCalculator
          itemChosen={props.itemChosen}
          totalAmount={props.totalAmount}
          parkingCost={props.parkingCost}
          perCost={props.perCost}
          weeklyAmount={props.weeklyAmount}
          back={props.back}
          remainingWeek={currentCampaign.remainingWeek}
        />
      )
    }
  }
  const paymentNumber = ['one', 'two', 'three', 'four']

  const { itemChosen, totalAmount, weeklyAmount } = props
  return (
    <>
      <div className="calculator-wrapper">
        <div className="calculator-header">
          <h1>
            Calculate your amount based on what you spend on&nbsp;
            <span style={{ color: '#67B4D6' }}>{itemChosen}</span>
          </h1>
        </div>
        <span>{calculatorSelector(itemChosen)} </span>
      </div>
      <div className="calculator-bottom-box">
        <h2>Your pledge is </h2>
        <h1 style={{ color: '#67B4D6' }}>
          {props.country === Country.NZ ? '$' : '£'}
          {totalAmount}
        </h1>
        <p style={{ fontSize: '15px', fontWeight: '500' }}>
          There will be {paymentNumber[currentCampaign.remainingWeek - 1]} weekly payments of{' '}
          {props.country === Country.NZ ? '$' : '£'}
          {weeklyAmount}.
        </p>
        <p style={{ fontSize: '13px' }}>
          We'll summarise your pledge before you enter your payment details.
        </p>
      </div>
      <p className={totalAmount < 10 ? 'validation-show' : 'validation-disappear'}>
        Please ensure your pledge is at least {props.country === Country.NZ ? '$' : '£'}10
      </p>
    </>
  )
}
