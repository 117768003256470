import { useState } from 'react'

export function useRecipient() {
  const [recipientID, setRecipientID] = useState<any>([])
  const [recipientNames, setRecipientNames] = useState<any>([])
  const [recipientImpactCosts, setRecipientImpactCosts] = useState<any>([])
  const [recipientImpactUnits, setRecipientImpactUnits] = useState<any>([])
  const [recipientDescriptions, setRecipientDescriptions] = useState<any>([])
  const [recipientLogos, setRecipientLogos] = useState<any>([])
  const [recipientLink, setRecilientLink] = useState<any>([])
  const [recipientDefaultNames, setRecipientDefaultNames] = useState<any>([])
  const [recipientDefaultLogos, setRecipientDefaultLogos] = useState<any>([])
  const [recipientDefaultDescriptions, setRecipientDefaultDescriptions] = useState<any>([])
  const [recipientDefaultImpactCosts, setRecipientDefaultImpactCosts] = useState<any>([])
  const [recipientDefaultImpactUnits, setRecipientDefaultImpactUnits] = useState<any>([])

  const getDefaultRecipients = async (country: String) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/recipient/default/${country}`)
        .then((res) => res.json())
        .then((data) => {
          setRecipientDefaultNames(data.map((item: any) => item.recipient_name))
          setRecipientDefaultLogos(data.map((item: any) => item.logo))
          setRecipientDefaultDescriptions(data.map((item: any) => item.impact_description))
          setRecipientDefaultImpactCosts(data.map((item: any) => item.impact_cost))
          setRecipientDefaultImpactUnits(data.map((item: any) => item.impact_unit))
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getRecipients = async (country: String) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/recipient/campaign/${country}`)
        .then((response) => response.json())
        .then((data) => {
          setRecipientID(data.map((item: any) => item.id))
          setRecipientNames(data.map((item: any) => item.recipient_name))
          setRecipientImpactCosts(data.map((item: any) => item.impact_cost))
          setRecipientImpactUnits(data.map((item: any) => item.impact_unit))
          setRecipientDescriptions(data.map((item: any) => item.impact_description))
          setRecipientLogos(data.map((item: any) => item.logo))
          setRecilientLink(data.map((item: any) => item.link))
        })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    recipientID,
    recipientNames,
    recipientImpactCosts,
    recipientImpactUnits,
    recipientDescriptions,
    recipientLogos,
    recipientLink,
    getRecipients,
    getDefaultRecipients,
    recipientDefaultNames,
    recipientDefaultLogos,
    recipientDefaultDescriptions,
    recipientDefaultImpactCosts,
    recipientDefaultImpactUnits,
  }
}
