import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { ThemeProvider, createTheme } from '@mui/material'
import MaterialTable from 'material-table'
import '../../css/admin.css'
import { makeStyles } from '@material-ui/core/styles'

export const UserList = (props: { userPermission: string }) => {
  const auth = useAuth()
  const history = useHistory()
  const [usersData, setUsersData] = useState([])
  const defaultMaterialsTheme = createTheme()
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  if (props.userPermission !== 'ROLE_ADMIN') {
    history.push('/login')
  }

  const useStyles = makeStyles({
    wrapper: {
      '& .MuiInput-root:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-root:hover:before': {
        borderBottom: 'none',
      },
    },
  })

  const classes = useStyles()

  const never: 'always' | 'onUpdate' | 'onAdd' | 'never' = 'never'

  const columns = [
    { title: 'ID', field: 'id', editable: never },
    {
      title: 'Name',
      render: (rowData: { first_name: string; last_name: string }) =>
        rowData.last_name + ', ' + rowData.first_name,
      customFilterAndSearch: (term: string, rowData: { first_name: string; last_name: string }) =>
        (rowData.first_name.toLowerCase() + ', ' + rowData.last_name.toLowerCase()).indexOf(
          term.toLowerCase()
        ) != -1,
      customSort: (a: { last_name: string }, b: { last_name: string }) =>
        a.last_name.localeCompare(b.last_name),
      editable: never,
    },
    { title: 'Country', field: 'country', editable: never },
    { title: 'Give Better News', field: 'opt_in_givebetter_comms', editable: never },
    { title: 'Charity News', field: 'opt_in_charity_comms', editable: never },
    { title: 'Email', field: 'email', editable: never },
    { title: 'Address', field: 'user_address', editable: never },
    { title: 'Activated', field: 'activated', editable: never },
  ]

  const getUsersData = useCallback(async () => {
    setIsError(false)

    await fetch(`${process.env.REACT_APP_API_URL}/api/auth/all_users`, {
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.forEach((user: any) => {
          user.opt_in_givebetter_comms = user.opt_in_givebetter_comms ? 'Yes' : 'No'
          user.opt_in_charity_comms = user.opt_in_charity_comms ? 'Yes' : 'No'
          user.activated = user.activated ? 'Yes' : 'No'
        })
        setUsersData(data)
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.message)
      })
  }, [auth.accessToken])

  useEffect(() => {
    getUsersData()
  }, [getUsersData, setUsersData])

  return (
    <div className="admin-table-wrapper">
      <div className={classes.wrapper}>
        <ThemeProvider theme={defaultMaterialsTheme}>
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <MaterialTable title="Users" columns={columns} data={usersData} />
        </ThemeProvider>
      </div>
    </div>
  )
}
