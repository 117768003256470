import { useEffect, useState } from 'react'
import PubSub from 'pubsub-js'
import Stepper from '../components/StepperPage/Stepper'
import { Country } from '../types/enums'

export default function Challenge() {
  const [country, setCountry] = useState(Country.NZ)
  const [itemChosen, setItemChosen] = useState('')
  const [charityID, setCharityID] = useState(0)
  const [challengeID, setChallengeID] = useState(0)
  const [campaignID, setCampaignID] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalTimes, setTotalTimes] = useState(0)
  const [parkingCost, setParkingCost] = useState(0)
  const [weeklyAmount, setWeeklyAmount] = useState(0)
  const [perCost, setPerCost] = useState(0)
  const [parkingChecked, setParkingChecked] = useState(false)

  let token1 = ''
  let token2 = ''
  let token3 = ''
  let token4 = ''
  let token5 = ''
  let token6 = ''
  let token7 = ''
  let token8 = ''
  let token9 = ''
  let token10 = ''
  let token11 = ''

  const getCampaignID = async (country: String) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/campaign/closest/${country}`
      )
      const data = await response.json()
      PubSub.publish('campaignID', data.id)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getCampaignID(country === Country.NZ ? 'NZ' : 'UK')
  }, [country])

  useEffect(() => {
    const subscribe = () => {
      token1 = PubSub.subscribe('item', (smg, item) => {
        setItemChosen(item)
      })
      token2 = PubSub.subscribe('total', (smg, total) => {
        setTotalAmount(total)
      })
      token3 = PubSub.subscribe('times', (smg, times) => {
        setTotalTimes(times)
      })
      token4 = PubSub.subscribe('perCost', (smg, cost) => {
        setPerCost(cost)
      })
      token5 = PubSub.subscribe('weeklyAmount', (smg, weeklyCost) => {
        setWeeklyAmount(weeklyCost)
      })
      token6 = PubSub.subscribe('charityID', (smg, charityIDs) => {
        setCharityID(charityIDs)
      })
      token7 = PubSub.subscribe('challengeID', (smg, challengeIDs) => {
        setChallengeID(challengeIDs)
      })
      token8 = PubSub.subscribe('campaignID', (smg, campaignIDs) => {
        setCampaignID(campaignIDs)
      })
      token9 = PubSub.subscribe('parkingChecked', (smg, parkingInfo) => {
        setParkingChecked(parkingInfo)
      })
      token10 = PubSub.subscribe('parking', (smg, parking) => {
        setParkingCost(parking)
      })
      token11 = PubSub.subscribe('campaignCountry', (smg, campaignCountries) => {
        setCountry(campaignCountries === 'NZ' ? Country.NZ : Country.UK)
      })
    }

    subscribe()

    return () => {
      PubSub.unsubscribe(token1)
      PubSub.unsubscribe(token2)
      PubSub.unsubscribe(token3)
      PubSub.unsubscribe(token4)
      PubSub.unsubscribe(token5)
      PubSub.unsubscribe(token6)
      PubSub.unsubscribe(token7)
      PubSub.unsubscribe(token8)
      PubSub.unsubscribe(token9)
      PubSub.unsubscribe(token10)
      PubSub.unsubscribe(token11)
    }
  })

  useEffect(() => {
    if (totalAmount.toString() === 'NaN') {
      setTotalAmount(0)
    } else if (weeklyAmount.toString() === 'NaN') {
      setWeeklyAmount(0)
    }
  }, [totalAmount, weeklyAmount])

  localStorage.clear()

  return (
    <>
      <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
        <Stepper
          itemChosen={itemChosen}
          charityID={charityID}
          challengeID={challengeID}
          campaignID={campaignID}
          totalAmount={totalAmount}
          totalTimes={totalTimes}
          parkingCost={parkingCost}
          perCost={perCost}
          weeklyAmount={weeklyAmount}
          country={country}
          parkingChecked={parkingChecked}
        />
      </div>
      <></>
    </>
  )
}
