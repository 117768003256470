import { Link } from 'react-router-dom'

export default function WhoIsGiveBetter() {
  return (
    <div className="landing-wrapper">
      <div className="landing-background">
        <div className="h1-section">
          <h1>Who is Give Better?</h1>
        </div>
        <div className="who-gb-wrapper">
          <div className="who-gb-box">
            <p>
              We are a charitable trust registered in New Zealand. We dream of a movement of people,
              empowered to make small changes to our spending habits. So, together, we unleash a
              tidal wave of generosity.
            </p>
            <p>
              Learn more about our story and team&nbsp;
              <Link to="/about" style={{ color: 'white' }}>
                here
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
