import { useCallback, useEffect, useState } from 'react'
import SimpleSlider from '../Slider'
import ChallengeDisplay from './ChallengeDisplay'
import PotentialCalculator from './PotentialCalculator'
import { Country } from '../../types/enums'

export default function HowItWorkSection(props: {
  country: Country
  currencySymbol: string
  campaignName: string[]
  recipientName: string[]
  recipientImpactCost: number[]
  recipientImpactUnit: string[]
  recipientDescription: string[]
  recipientLogo: string[]
  campaignIsActive: boolean
  recipientDefaultName: string[]
  recipientDefaultImpactCost: number[]
  recipientDefaultImpactUnit: string[]
  recipientDefaultDescription: string[]
  recipientDefaultLogo: string[]
}) {
  const currencySymbol = props.country === Country.NZ ? '$' : '£'
  const [slideTop, setSlideTop] = useState<string[]>([])
  const [slideLogo, setSlideLogo] = useState<string[]>([])
  const [slideBottom, setSlideBottom] = useState<string[]>([])

  const getSlideInfo = useCallback(() => {
    const slideTop: string[] = []
    const slideLogo: string[] = []
    const slideBottom: string[] = []

    if (props.campaignIsActive) {
      props.recipientLogo.map((item: any, index: any) => {
        const topDesc =
          'With ' +
          props.recipientName[index] +
          ', every ' +
          currencySymbol +
          Number(props.recipientImpactCost[index]).toFixed(2) +
          ' you pledge can...'

        if (props.recipientName[index] === 'Youthline') {
          const bottomDesc =
            'provide 7 ' +
            (props.recipientImpactUnit[index] + 's') +
            ' ' +
            props.recipientDescription[index]
          slideBottom.push(bottomDesc)
        } else if (props.recipientName[index] === 'Outward Bound') {
          const bottomDesc =
            'provide 1 ' +
            props.recipientImpactUnit[index] +
            ' for a hungry student on their leadership programme'
          slideBottom.push(bottomDesc)
        } else {
          const bottomDesc =
            'provide 1 ' +
            props.recipientImpactUnit[index] +
            ' ' +
            props.recipientDescription[index]
          slideBottom.push(bottomDesc)
        }
        slideTop.push(topDesc)
        slideLogo.push(item)
        return null
      })

      if (slideTop.length === 1) {
        slideTop.push(slideTop[0])
        slideLogo.push(slideLogo[0])
        slideBottom.push(slideBottom[0])
      }
    } else {
      props.recipientDefaultLogo.map((item: any, index: any) => {
        const topDesc =
          'With ' +
          props.recipientDefaultName[index] +
          ', every ' +
          currencySymbol +
          Number(props.recipientDefaultImpactCost[index]).toFixed(2) +
          ' you pledge can...'

        if (props.recipientDefaultName[index] === 'Youthline') {
          const bottomDesc =
            'provide 7 ' +
            (props.recipientDefaultImpactUnit[index] + 's') +
            ' ' +
            props.recipientDefaultDescription[index]
          slideBottom.push(bottomDesc)
        } else if (props.recipientDefaultName[index] === 'Outward Bound') {
          const bottomDesc =
            'provide 1 ' +
            props.recipientDefaultImpactUnit[index] +
            ' for a hungry student on their leadership programme'
          slideBottom.push(bottomDesc)
        } else {
          const bottomDesc =
            'provide 1 ' +
            props.recipientDefaultImpactUnit[index] +
            ' ' +
            props.recipientDefaultDescription[index]
          slideBottom.push(bottomDesc)
        }
        slideTop.push(topDesc)
        slideLogo.push(item)
        return null
      })

      if (slideTop.length === 1) {
        slideTop.push(slideTop[0])
        slideLogo.push(slideLogo[0])
        slideBottom.push(slideBottom[0])
      }
    }

    setSlideTop(slideTop)
    setSlideLogo(slideLogo)
    setSlideBottom(slideBottom)
  }, [
    currencySymbol,
    props.recipientDescription,
    props.recipientImpactCost,
    props.recipientImpactUnit,
    props.recipientLogo,
    props.recipientName,
    props.recipientDefaultDescription,
    props.recipientDefaultImpactCost,
    props.recipientDefaultImpactUnit,
    props.recipientDefaultLogo,
    props.recipientDefaultName,
    props.campaignIsActive,
  ])

  useEffect(() => {
    getSlideInfo()
  }, [props.recipientLogo, getSlideInfo])

  return (
    <div className="outer">
      <div className="h1-normal-section">
        <h1>How does the challenge work?</h1>
      </div>
      <div className="howitwork">
        <div className="first-part-wrapper">
          <div className="rect" id="rectangle1">
            <div className="head-wrapper">
              <div className="circle">1</div>
              <p className="title">Give Up</p>
            </div>
            <p className="paragraph">
              {!props.campaignIsActive
                ? 'For one month'
                : 'For the month of ' + props.campaignName[0]}
              , challenge yourself to let go of one area of spending.
            </p>
          </div>
          <div className="challenge-display">
            <ChallengeDisplay
              country={props.country}
              currencySymbol={currencySymbol}
              campaignIsActive={props.campaignIsActive}
            />
          </div>
        </div>
        <div className="second-part-wrapper">
          <PotentialCalculator currencySymbol={currencySymbol} />
          <div className="rect" id="rectangle2">
            <div className="head-wrapper">
              <div className="circle">2</div>
              <p className="title">Free Up</p>
            </div>
            <p className="paragraph">
              We help you calculate how much you'll be saving by doing the challenge.{' '}
            </p>
          </div>
        </div>
        <div className="third-part-wrapper">
          <div className="rect" id="rectangle3">
            <div className="head-wrapper">
              <div className="circle">3</div>
              <p className="title">Give Better</p>
            </div>
            <p className="paragraph">
              Donate the amount you've saved and make an impact with one of our partnered causes!{' '}
              <br></br>
              <a className="link-to-concerned" href="/#concerned-section">
                Wondering how payment works?
              </a>
            </p>
          </div>
          <div className="slide">
            <SimpleSlider topDesc={slideTop} logo={slideLogo} bottomDesc={slideBottom} />
          </div>
        </div>
      </div>
    </div>
  )
}
