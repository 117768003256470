import { useEffect, useState } from 'react'
import GroupCharities from './GroupedCharities'
import { Country } from '../../types/enums'
import { useRecipient } from '../../hooks/recipient'
import { usePledge } from '../../hooks/pledge'

export let status: boolean
export function setStatus(tempStatus: any) {
  status = tempStatus
}
export function ChooseCause(props: {
  totalAmount: any
  country: Country
  charityIDs: any[]
  charityName: any[]
  charityUnit: any[]
  charityDesc: any[]
  charityLogo: any[]
  charityLink: any[]
  charityImpactCost: any[]
}) {
  const countryString = props.country === Country.NZ ? 'NZ' : 'UK'
  const [charities, setCharities] = useState<any>([])
  const [possibleImpact, setPossibleImpact] = useState<any[]>([])
  const recipientData = useRecipient()
  const pledgeData = usePledge()

  useEffect(() => {
    const getInfo = async () => {
      pledgeData.getTotalCharityAmount(countryString)
      pledgeData.getTotalCharityPledges(countryString)
    }
    getInfo()
  }, [props.country])

  const getCharitiesData = async () => {
    const possibleImpact: string[] = []
    recipientData.getRecipients(countryString)
    props.charityLogo.map((item: any, index: any) => {
      if (props.charityName[index] === 'Youthline') {
        const youthLineImpact = ((props.totalAmount / props.charityImpactCost[index]) * 7).toFixed(
          0
        )
        possibleImpact.push(youthLineImpact)
        setPossibleImpact(possibleImpact)
      } else {
        const impact = (props.totalAmount / props.charityImpactCost[index]).toFixed(0)
        possibleImpact.push(impact)
        setPossibleImpact(possibleImpact)
      }
      return null
    })
  }
  useEffect(() => {
    getCharitiesData()
  }, [props.charityLogo])

  const updateSelect = (id: string, select: boolean, charityIDs: Number) => {
    const c = { [id]: select }
    if (c[id]) {
      status = true
    } else {
      status = false
    }

    let newCharitySelection: Array<boolean>
    newCharitySelection = []
    if (props.country === Country.NZ || props.country === Country.UK) {
      charities.map((item: any, key: any) => {
        newCharitySelection.push(false)
        return null
      })
      setCharities(newCharitySelection)
      newCharitySelection[Number(id)] = select
      setCharities(newCharitySelection)
    }

    PubSub.publish('charityID', charityIDs)
  }

  const loadCharities = () => {
    const list = []
    const unitList: string[] = []

    props.charityUnit.map((item: any, key: any) => {
      if (possibleImpact[key] >= 2 || possibleImpact[key] === 0) {
        unitList.push(props.charityUnit[key] + 's')
      } else {
        unitList.push(props.charityUnit[key])
      }
      return null
    })

    for (let i = 0; i < props.charityName.length; i++) {
      list.push(
        <GroupCharities
          charityID={props.charityIDs[i]}
          charityName={props.charityName[i]}
          logo={props.charityLogo[i]}
          pledgeAmount={pledgeData.totalCharityAmount[i]}
          pledgeNumber={pledgeData.totalCharityPledges[i]}
          possibleImpact={possibleImpact[i]}
          impactUnit={unitList[i]}
          totalAmount={props.totalAmount}
          description={props.charityDesc[i]}
          link={props.charityLink[i]}
          ifSelect={charities[i]}
          id={i.toString()}
          key={i}
          updateSelect={updateSelect}
          country={props.country}
        />
      )
    }
    return list
  }

  return (
    <div className="chooseCause-wrapper">
      <h2>
        Choose where your pledge will be donated to and discover the impact your{' '}
        <span>
          {props.country === Country.NZ ? '$' : '£'}
          {props.totalAmount.toFixed(2)}
        </span>{' '}
        can make with each charity.
      </h2>
      <div className="stepper-content-wrapper">{loadCharities()}</div>
    </div>
  )
}
