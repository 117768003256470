import { useEffect, useState } from 'react'
import { useChallenges } from '../../hooks/challenge'
import { Country } from '../../types/enums'
import GroupedChallenges from './GroupedChallenges'

export let challengeStatus: boolean
export function setChallengeStatus(tempStatus: any) {
  challengeStatus = tempStatus
}
export function ChooseChallenge(props: {
  country: Country
  challengeIDs: any[]
  challengesName: any[]
  challengesIcon: any[]
  challengesDesc: any[]
  challengesHashtag: any[]
  challengesHashtagLink: any[]
}) {
  const countryString = props.country === Country.NZ ? 'NZ' : 'UK'
  const [challenges, setChallenges] = useState<any>([])
  const challenge = useChallenges()
  useEffect(() => {
    const getInfo = async () => {
      challenge.getChallenges(countryString)
    }
    getInfo()
  }, [props.country])

  useEffect(() => {
    PubSub.publish('display', 'none')
  }, [])

  const updateSelection = (
    id: string,
    selected: boolean,
    challengeName: string,
    challengeIDs: Number
  ) => {
    const c = { [id]: selected }
    if (c[id]) {
      challengeStatus = true
    } else {
      challengeStatus = false
    }

    let newChallenges: Array<boolean>
    newChallenges = []
    if (props.country === Country.NZ || props.country === Country.UK) {
      props.challengesIcon.map((item: any, key: any) => {
        newChallenges.push(false)
        return null
      })
      setChallenges(newChallenges)
      newChallenges[Number(id)] = selected
      setChallenges(newChallenges)
    }
    PubSub.publish('item', challengeName)
    PubSub.publish('challengeID', challengeIDs)
  }

  const loadChallenge = () => {
    const list = []
    for (let i = 0; i < props.challengesName.length; i++) {
      list.push(
        <GroupedChallenges
          key={i}
          challengeID={props.challengeIDs[i]}
          challengeName={props.challengesName[i]}
          challengeImg={props.challengesIcon[i]}
          challengeDesc={props.challengesDesc[i]}
          challengeHashtag={props.challengesHashtag[i]}
          challengesHashtagLink={props.challengesHashtagLink[i]}
          ifSelected={challenges[i]}
          id={i.toString()}
          updateSelect={updateSelection}
        />
      )
    }
    return list
  }

  return (
    <div className="choose-challenge-wrapper">
      <h2>Choose an area to challenge yourself and free up your spending</h2>
      <div className="stepper-content-wrapper">{loadChallenge()}</div>
    </div>
  )
}
