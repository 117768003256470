import { Button } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

import fb from '../../images/mediaAppLogo/shareFB.svg'
import twitter from '../../images/mediaAppLogo/shareTW.svg'
import { useAuth } from '../../hooks/useAuth'
import '../../css/sharelink.css'

export default function SubmitPage() {
  const auth = useAuth()
  const [name, setNames] = useState([])
  const [email, setEmail] = useState('')
  const [paymentDate, setPaymentDate] = useState(new Date())
  const [paymentAmount, setPaymentAmount] = useState(0)
  const [country, setCountry] = useState('')
  const [currency, setCurrency] = useState('')

  const token = auth.accessToken
  const fetchUser = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/user_info`, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      setNames(data.first_name)
      setEmail(data.email)
    } catch (error) {
      console.log('Error:', error)
    }
  }, [token])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  const fetchPayments = useCallback(async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/payment/`, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((value) => value.json())
        .then((payments: Array<Payments>) => {
          const pledgeId = payments[0].pledge_id
          const pendingPayment = payments.find(
            (payment: Payments) => payment.payment_status === 'Pending'
          )

          if (pendingPayment) {
            const firstPayment = payments.shift()
            if (firstPayment) {
              setPaymentDate(new Date(firstPayment.processing_date))
              setPaymentAmount(Number(firstPayment.amount))
            }
          }

          return Promise.all([
            fetch(`${process.env.REACT_APP_API_URL}/api/pledge/${pledgeId}`)
              .then((value) => value.json())
              .then((recipients) => {
                const campaignId = recipients.campaign_id

                fetch(`${process.env.REACT_APP_API_URL}/api/campaign/${campaignId}`)
                  .then((value) => value.json())
                  .then((campaigns) => {
                    setCountry(campaigns.country)
                  })
              }),
          ])
        })
    } catch (e) {
      console.log(e)
    }
  }, [token])

  useEffect(() => {
    setTimeout(() => {
      fetchPayments()
    }, 1000)
  }, [token, fetchPayments])

  useEffect(() => {
    setCurrency(country === 'NZ' ? '$' : '£')
  }, [country])

  return (
    <>
      <div
        className="socialmedia"
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          alignContent: 'center',
          marginBottom: '26px',
        }}
      >
        <div className="shareContent">
          <span className="shareContent-top" style={{ marginBottom: 30 }}>
            <span>
              Thank you {name}! We have sent a confirmation email to {email}
            </span>
          </span>
          <span className="shareContent-middle" style={{ marginBottom: 30 }}>
            <span>
              Your first weekly payment of{' '}
              <b>
                {currency}
                {paymentAmount} {country === 'NZ' ? 'NZD' : 'GBP'}
              </b>{' '}
              will be on{' '}
              <b>
                {paymentDate.getDate()}/{paymentDate.getMonth() + 1}/{paymentDate.getFullYear()}
              </b>
              , you can view your progress and cancel your donation by going to the “View my pledge”
              page on the Give Better website.
            </span>
          </span>
          <span className="shareContent-last">
            <p>Share your Challenge to let others join in with you!</p>
          </span>
        </div>
        <div className="sharelinks">
          <div
            className="share-button"
            data-href="https://developers.facebook.com/docs/plugins/"
            data-layout="button_count"
            data-size="large"
          >
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fgivebetter.nz%2F&amp;src=sdkpreparse"
              className="fb-xfbml-parse-ignore"
            >
              <img src={fb} alt="fb"></img>
            </a>
          </div>

          <div className="share-button">
            <a href="https://twitter.com/intent/tweet" target="_blank" rel="noreferrer">
              <img src={twitter} alt="twitter"></img>
            </a>
          </div>
        </div>
        <div className="rec-wrapper" style={{ paddingTop: '5%' }}>
          <div className="rec">
            <div className="linkicon">
              <FontAwesomeIcon style={{ color: 'white' }} icon={faLink} size="2x"></FontAwesomeIcon>
            </div>
            <div className="linkcaption">
              <p>givebetter.nz/</p>
            </div>
            <span className="copybutton-outer">
              <Button
                className="copybutton"
                onClick={() => {
                  navigator.clipboard.writeText('givebetter.nz/')
                }}
              >
                Copy
              </Button>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
