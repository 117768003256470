import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import ReactLoading from 'react-loading'

import { recoverPasswordSchema } from '../../components/Authorization/YupSchema'
import { useAuth } from '../../hooks/useAuth'
import '../../css/authorization.css'

export const RecoveryPage = () => {
  const auth = useAuth()
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [isSuccessfullySubmitted, setIsSuccessfullySubmit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(recoverPasswordSchema),
  })

  const recover = async () => {
    setIsLoading(true)

    await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Invalid email')
        } else {
          setTimeout(() => {
            setIsSuccessfullySubmit(true)
          }, 10)
          setEmail('')
          setTimeout(() => {
            history.push('/reset-password')
          }, 1500)
        }
      })
      .catch((error) => {
        setError('emailError', { message: 'Not a valid email' })
        setIsLoading(false)
      })
      .finally(() => {
        setIsSuccessfullySubmit(false)
      })
  }

  if (auth.isAuthenticated) {
    history.push('/')
    return <div>Already logged in. Redirecting you to the home page...</div>
  }

  const onKeyEnter = (event: any) => {
    if (event.keyCode === 13) {
      handleSubmit(recover)
    }
  }

  if (isLoading === true) {
    return (
      <div className="loader">
        <ReactLoading type={'bars'} color={'#67b4d6'} /> Loading
      </div>
    )
  }

  return (
    <div className="Form-wrapper" style={{ marginBottom: '20.625em' }}>
      <div className="Form" style={{ textAlign: 'left' }}>
        <h1>Recover Password</h1>
        <br />
        <form onSubmit={handleSubmit(recover)}>
          <input
            {...register('email')}
            type="text"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={onKeyEnter}
          />
          <p>{errors.email?.message || errors.emailError?.message}</p>
          <div className="button-wrapper">
            <Button
              style={{ marginTop: '0.5em' }}
              type="submit"
              disabled={isSubmitting || isSuccessfullySubmitted}
              onKeyDown={onKeyEnter}
            >
              Recover
            </Button>
            {isSuccessfullySubmitted && (
              <div className="submitMsg">
                <span>
                  <br />
                  Please check your email.
                  <br />
                  You will now be redirected to the password reset page...
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
