import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { ThemeProvider, createTheme, Chip, Paper } from '@mui/material'
import MaterialTable, { MTableToolbar } from 'material-table'
import '../../css/admin.css'
import { makeStyles } from '@material-ui/core/styles'

export const AdminCampaign = (props: { userPermission: string }) => {
  const auth = useAuth()
  const history = useHistory()
  const [campaignData, setCampaignData] = useState([])
  const defaultMaterialsTheme = createTheme()
  const [isDeactivated, setIsDeactivated] = useState(false)
  const [deactivatedMessage, setDeactivatedMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  if (props.userPermission !== 'ROLE_ADMIN') {
    history.push('/login')
  }

  const useStyles = makeStyles({
    wrapper: {
      '& .MuiInput-root:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-root:hover:before': {
        borderBottom: 'none',
      },
    },
  })

  const classes = useStyles()

  const never: 'always' | 'onUpdate' | 'onAdd' | 'never' = 'never'

  const columns = [
    { title: 'ID', field: 'id', editable: never },
    { title: 'Campaign Name', field: 'campaign_name' },
    { title: 'Country', field: 'country' },
    { title: 'Start Date', field: 'start_date' },
    { title: 'End Date', field: 'end_date' },
    { title: 'Billing Start Date', field: 'billing_start_date' },
    { title: 'Active', field: 'is_active', editable: never },
  ]

  const convertDate = (date: string) => {
    const dateArray = date.split('-')
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
  }

  const getCampaignData = useCallback(async () => {
    setIsError(false)

    await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/`, {
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.forEach((campaign: any) => {
          campaign.start_date = convertDate(campaign.start_date.slice(0, 10))
          campaign.end_date = convertDate(campaign.end_date.slice(0, 10))
          campaign.billing_start_date = convertDate(campaign.billing_start_date.slice(0, 10))
          campaign.is_active = campaign.is_active ? 'Yes' : 'No'
        })
        setCampaignData(data)
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.message)
      })
  }, [auth.accessToken])

  useEffect(() => {
    getCampaignData()
  }, [getCampaignData, setCampaignData])

  const addCampaign = async (campaign: any) => {
    setIsError(false)

    campaign.start_date = new Date(convertDate(campaign.start_date))
    campaign.end_date = new Date(convertDate(campaign.end_date))
    campaign.billing_start_date = new Date(convertDate(campaign.billing_start_date))

    await fetch(`${process.env.REACT_APP_API_URL}/api/campaign`, {
      method: 'POST',
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        campaign_name: campaign.campaign_name,
        start_date: campaign.start_date,
        end_date: campaign.end_date,
        country: campaign.country,
        billing_start_date: campaign.billing_start_date,
        billing_cycle: 'Weekly',
        is_active: true,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        getCampaignData()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage('Could not add campaign')
      })
  }

  const deleteCampaign = async (campaign: any) => {
    setIsError(false)

    await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/${campaign.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getCampaignData()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage('Could not delete campaign')
      })
  }

  const editCampaign = async (campaign: any) => {
    setIsError(false)

    campaign.start_date = new Date(campaign.start_date)
    campaign.end_date = new Date(campaign.end_date)
    campaign.billing_start_date = new Date(campaign.billing_start_date)

    await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/${campaign.id}`, {
      method: 'PUT',
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        campaign_name: campaign.campaign_name,
        start_date: campaign.start_date,
        end_date: campaign.end_date,
        country: campaign.country,
        billing_start_date: campaign.billing_start_date,
        billing_cycle: 'Weekly',
        is_active: true,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        getCampaignData()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage('Could not edit campaign')
      })
  }

  const deactivateCampaign = async () => {
    setIsError(false)
    setIsDeactivated(false)

    await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/deactivate`, {
      method: 'POST',
      headers: {
        Authorization: `${auth.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setIsDeactivated(true)
        setDeactivatedMessage(data)
        getCampaignData()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage('Could not deactivate campaigns')
      })
  }

  return (
    <div className="admin-table-wrapper">
      <ThemeProvider theme={defaultMaterialsTheme}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <MaterialTable
          title="Campaigns"
          columns={columns}
          data={campaignData}
          components={{
            Toolbar: (props) => (
              <div className={classes.wrapper}>
                <MTableToolbar {...props} />
                <div style={{ padding: '0px 10px', paddingBottom: '1em' }}>
                  <Chip
                    label="Deactivate Past Campaigns"
                    color="primary"
                    style={{ marginRight: 5 }}
                    onClick={() => deactivateCampaign()}
                  />
                </div>
                {isDeactivated && (
                  <div style={{ padding: '0px 10px' }}>
                    <Paper>{deactivatedMessage}</Paper>
                  </div>
                )}
                {isError && (
                  <div style={{ padding: '0px 10px' }}>
                    <Paper style={{ color: 'red' }}>{errorMessage}</Paper>
                  </div>
                )}
              </div>
            ),
          }}
          editable={{
            onRowAdd: (newData: any) => addCampaign(newData),
            onRowDelete: (oldData: any) => deleteCampaign(oldData),
            onRowUpdate: (newData: any, oldData: any) => editCampaign(newData),
          }}
        />
      </ThemeProvider>
    </div>
  )
}
