import { Route, useHistory, useLocation, Switch } from 'react-router-dom'
import ReorderIcon from '@mui/icons-material/Reorder'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState, useCallback, useRef, useLayoutEffect } from 'react'
import axios from 'axios'
import 'dotenv/config'

import '../src/css/home.css'
import './App.css'

import logo from './images/titlePart/GBLogo.svg'
import Home from './containers/Home'
import { About } from './containers/About'
import { ViewPledge } from './containers/ViewPledge'
import Challenge from './containers/Challenge'
import { useAuth } from './hooks/useAuth'
import SubmitPage from './components/StepperPage/SubmitPage'
import { LoginPage } from './components/Authorization/LoginPage'
import { ActivationPage } from './components/Authorization/ActivationPage'
import SummaryPledge from './containers/SummaryPledge'
import { RecoveryPage } from './components/Authorization/RecoveryPage'
import { ResetPage } from './components/Authorization/ResetPage'
import { Country } from './types/enums'
import { useCampaign } from './hooks/campaign'
import { PrivacyPolicy } from './containers/PrivacyPolicy'
import { Divider } from '@mui/material'
import { NotFound } from './components/404Message'
import { AdminPage } from './components/AdminPage/AdminPage'
import { AdminCampaign } from './components/AdminPage/AdminCampaign'
import { UserList } from './components/AdminPage/UserList'
import Footer from './components/Footer'

if (process.env.NODE_ENV === 'development') require('./setupDevelopment')

export default function App() {
  const history = useHistory()
  const auth = useAuth()
  const currentCampaign = useCampaign()

  const [country, setCountry] = useState<Country>(Country.NZ)
  const [pledgeStatus, setPledgeStatus] = useState<string>('')
  const [showLinks, setShowLinks] = useState(false)
  const [userPermission, setUserPermission] = useState('')
  const [showButton, setShowButton] = useState(false)

  let token = auth.accessToken
  let isAuthenticated = auth.isAuthenticated

  const loginAuth = localStorage.getItem('isAuthenticated')
  if (loginAuth !== null) {
    isAuthenticated = JSON.parse(loginAuth)
  }

  if (
    history.location.pathname === '/summary' &&
    localStorage.length !== 0 &&
    isAuthenticated !== false
  ) {
    const loginToken = localStorage.getItem('token')
    if (loginToken !== null) {
      token = JSON.parse(loginToken)
    }
  } else if (
    (history.location.pathname === '/summary' && localStorage.length === 0) ||
    (history.location.pathname === '/summary' && isAuthenticated === false)
  ) {
    history.replace('/login')
  }

  const handleLogout = async () => {
    await auth.logout()
    setUserPermission('')
    localStorage.clear()
    history.push('/')
  }

  const handleScroll = () => {
    if (showLinks) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    window.addEventListener('mousedown', handleClickOutside)
  }

  useEffect(() => {
    const getIpAddress = async () => {
      const response = await axios.get('https://geolocation-db.com/json/')
      if (response.data.country_name === 'New Zealand') {
        setCountry(Country.NZ)
      } else if (response.data.country_name === 'United Kingdom') {
        setCountry(Country.UK)
      } else {
        setCountry(Country.NZ)
      }
    }
    getIpAddress()
  }, [])

  const hamburgerHandler = () => {
    if (!showLinks) {
      return (
        <button onClick={() => setShowLinks(!showLinks)}>
          <ReorderIcon />
        </button>
      )
    } else {
      return (
        <button onClick={() => setShowLinks(!showLinks)}>
          <CloseIcon />
        </button>
      )
    }
  }

  const handleClickOutside = useCallback(
    (event: any) => {
      if (showLinks && !event.target.closest('.nav-links')) {
        setShowLinks(false)
      }
    },
    [showLinks]
  )

  const switcher = (event: any) => {
    if (history.location.pathname === '/') {
      if (event.target.value === 'NZ') {
        setCountry(Country.NZ)
      } else if (event.target.value === 'UK') {
        setCountry(Country.UK)
      }
    }
  }

  const getDefaultAddress = () => {
    if (country === 2) {
      return 'UK'
    } else if (country === 1) {
      return 'NZ'
    }
  }

  const checkPledgeStatus = useCallback(async () => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}/api/pledge/closest`, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'No pledges!') {
            setPledgeStatus('Cancelled')
          } else if (data.message !== 'No pledges!') {
            setPledgeStatus(data.pledge_status)
          }
        })
    } catch (e) {
      console.log(e)
    }
  }, [token])

  const path = useLocation()
  useEffect(() => {
    if (isAuthenticated) {
      checkPledgeStatus()
    }
  }, [auth.accessToken, isAuthenticated, path, checkPledgeStatus])

  useEffect(() => {
    const permissions = () => {
      auth.parsedToken?.permissions.forEach((permission) => {
        if (permission.permission === 'ROLE_ADMIN') {
          setUserPermission('ROLE_ADMIN')
        }
      })
    }
    permissions()
  }, [auth.parsedToken])

  handleScroll()

  const countryString = country === Country.NZ ? 'NZ' : 'UK'

  function getCampaign() {
    currentCampaign.getCampaign(countryString)
  }

  useEffect(() => {
    getCampaign()
  }, [country, currentCampaign.remainingWeek])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const targetref = useRef<HTMLInputElement>(null)
  const [height, setHeight] = useState(0)
  useLayoutEffect(() => {
    if (targetref.current) {
      setHeight(targetref.current.offsetHeight)
    }
  }, [path])

  const currentPath = useLocation().pathname

  const handleContactLink = () => {
    if (history.location.pathname === '/') {
      return '/#footer-link'
    } else {
      return `${process.env.REACT_APP_API_URL}${currentPath}#footer-link`
    }
  }

  const campaignNameSplit = currentCampaign.campaignName.split(' ')
  return (
    <div className="overall-wrapper" ref={targetref}>
      <div className="navbar">
        <div className="logo">
          <a href="/">
            <img src={logo} style={{ height: 54, width: 200 }} alt="logo" />
          </a>
        </div>
        <nav className="right-side-nav">
          <div className="nav-links" id={showLinks ? 'hidden' : ''}>
            <li onClick={() => history.push('/about')}>About</li>
            <li>
              <a
                className="link-to-footer"
                href={handleContactLink()}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Contact
              </a>
            </li>
            <li onClick={() => history.push('/viewPledge')}>View my Pledge</li>
            {userPermission === 'ROLE_ADMIN' && isAuthenticated ? (
              <li onClick={() => history.push('/admin')}>Admin</li>
            ) : null}
            {pledgeStatus === 'Confirmed' && isAuthenticated ? (
              <li className="challenge" onClick={handleLogout}>
                Log Out
              </li>
            ) : (pledgeStatus === 'Pending' || pledgeStatus === 'Cancelled') && isAuthenticated ? (
              <>
                <li onClick={handleLogout}>Log Out</li>
              </>
            ) : window.location.pathname !== '/challenge' ? (
              <>
                {!currentCampaign.campaignIsActive ? (
                  <li className="challenge">Campaign Coming Soon!</li>
                ) : (
                  <li className="challenge" onClick={() => history.push('/challenge')}>
                    {campaignNameSplit[0]} {campaignNameSplit[1]}
                  </li>
                )}
                <li>
                  <select
                    name="selection"
                    className="selector"
                    onChange={switcher}
                    value={getDefaultAddress()}
                  >
                    <option value="NZ">NZ</option>
                    <option value="UK">UK</option>
                  </select>
                </li>
              </>
            ) : (
              <br />
            )}
          </div>
          {hamburgerHandler()}
          <div className="overlay"></div>
        </nav>
      </div>

      <div>
        <Switch>
          <Route path="/" exact>
            <Home country={country} />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/admin">
            <AdminPage userPermission={userPermission} />
          </Route>
          <Route path="/admin-campaign">
            <AdminCampaign userPermission={userPermission} />
          </Route>
          <Route path="/user-list">
            <UserList userPermission={userPermission} />
          </Route>
          <Route path="/viewPledge">
            <ViewPledge />
          </Route>
          <Route path="/challenge">
            <Challenge />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/SubmitPage">
            <SubmitPage />
          </Route>
          <Route path="/activate">
            <ActivationPage />
          </Route>
          <Route path="/password-recovery">
            <RecoveryPage />
          </Route>
          <Route path="/reset-password">
            <ResetPage />
          </Route>
          <Route path="/summary">
            <SummaryPledge />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
      <div style={{ opacity: '0.9' }}>
        <Divider style={{ height: '2px' }}></Divider>
        <div className="footer">
          <Footer />
        </div>
        {showButton && (
          <button onClick={scrollToTop} className="back-to-top">
            &#8679;
          </button>
        )}
      </div>
    </div>
  )
}
