import { Country } from '../types/enums'
import blueArrow from '../images/blueArrow.svg'

import '../css/onboarding.css'

export default function GroupedGiveUpGiveBetter(props: {
  totalCount: Number //pledge - confirmed_amount
  chosenAct: any //challenge icons
  charityFor: any //charities logo
  chosenActDes: string //pledge - number of units
  charityForDes: string // = pledge.confirmed_amount/recipients.impact_cost + recipients.impact_unit
  country: any
}) {
  return (
    <div className="giveUpGiveBetter-wrapper">
      <div className="giveUp-wrapper">
        <p className="p1">Giving Up</p>
        <div className="image-wrapper">
          <img src={props.chosenAct} alt="chosenAct" />
        </div>
        <p className="p2">{props.chosenActDes}</p>
      </div>
      <div className="arrow-wrapper">
        <p>
          {props.country === Country.NZ ? '$' : '£'}
          {Number(props.totalCount).toFixed(2)}
        </p>
        <img src={blueArrow} alt="arrow" />
      </div>
      <div className="giveBetter-wrapper">
        <p className="p1">Giving Better</p>
        <div className="image-wrapper">
          <img src={props.charityFor} alt="charityFor" />
        </div>
        <p className="p2">{props.charityForDes}</p>
      </div>
    </div>
  )
}
