import { useEffect } from 'react'
import TitlePart from '../components/LandingPage/TitlePart'
import HowItWorkSection from '../components/LandingPage/HowItWorks'
import ConcernedPage from '../components/LandingPage/ConcernedMoney'
import SoWhyGiveBetter from '../components/LandingPage/SoWhyGiveBetter'
import WhoIsGiveBetter from '../components/LandingPage/WhoIsGiveBetter'
import ImpactAotearoa from '../components/LandingPage/ImpactAotearoa'
import { Country } from '../types/enums'
import { useCampaign } from '../hooks/campaign'
import { useRecipient } from '../hooks/recipient'

export default function Home(props: { country: Country }) {
  const { country } = props
  const countryString = props.country === Country.NZ ? 'NZ' : 'UK'
  const activeCampaign = useCampaign()
  const currentCampaign = useCampaign()
  const recipients = useRecipient()

  localStorage.clear()

  useEffect(() => {
    const getInfo = async () => {
      currentCampaign.getCampaign(countryString)
      recipients.getRecipients(countryString)
      activeCampaign.getActiveCampaign(countryString)
      recipients.getDefaultRecipients(countryString)
    }

    getInfo()
  }, [props.country])

  const campaignName = currentCampaign.campaignName.split(' ')

  return (
    <div>
      <TitlePart
        country={props.country}
        campaignName={campaignName}
        campaignStart={currentCampaign.startDate}
        campaignEnd={currentCampaign.endDate}
        campaignIsActive={currentCampaign.campaignIsActive}
        recipientLogo={recipients.recipientLogos}
        defaultRecipientLogos={recipients.recipientDefaultLogos}
      />
      <HowItWorkSection
        country={country}
        currencySymbol={props.country === Country.NZ ? '$' : '£'}
        campaignName={campaignName}
        recipientName={recipients.recipientNames}
        recipientImpactCost={recipients.recipientImpactCosts}
        recipientImpactUnit={recipients.recipientImpactUnits}
        recipientDescription={recipients.recipientDescriptions}
        recipientLogo={recipients.recipientLogos}
        campaignIsActive={currentCampaign.campaignIsActive}
        recipientDefaultName={recipients.recipientDefaultNames}
        recipientDefaultImpactCost={recipients.recipientDefaultImpactCosts}
        recipientDefaultImpactUnit={recipients.recipientDefaultImpactUnits}
        recipientDefaultDescription={recipients.recipientDefaultDescriptions}
        recipientDefaultLogo={recipients.recipientDefaultLogos}
      />
      <SoWhyGiveBetter />
      <ImpactAotearoa country={country} />
      <WhoIsGiveBetter />
      <ConcernedPage
        country={country}
        campaignName={campaignName}
        paymentStart={currentCampaign.firstPayment}
        campaignIsActive={currentCampaign.campaignIsActive}
      />
    </div>
  )
}
