import shareIcon from '../../images/shareIcon.svg'
import '../../css/onboarding.css'
import { Country } from '../../types/enums'

export default function GroupCharities(props: {
  charityID: Number
  charityName: string
  logo: any
  pledgeAmount: Number
  pledgeNumber: Number
  possibleImpact: any
  impactUnit: any
  totalAmount: Number
  description: string
  link: string
  ifSelect: boolean
  id: string
  country: Country
  updateSelect(id: string, select: boolean, charityID: Number): any
}) {
  const display = () => {
    props.updateSelect(props.id, !props.ifSelect, props.charityID)
  }
  return (
    <div onClick={display} className="charity-wrapper">
      <div
        className={['charity-innerWrapper', props.ifSelect === true ? 'selected' : null].join(' ')}
      >
        <div className="charity-name">{props.charityName}</div>
        <div className="data-wrapper">
          <div className="logo-wrapper">
            <img src={props.logo} alt="logo" />
          </div>
          <div>
            <p>
              <span>
                {props.country === Country.NZ ? '$' : '£'}
                {props.pledgeAmount}
              </span>{' '}
              pledged so far
            </p>
            <p>
              Join <span>{props.pledgeNumber}</span> others
            </p>
          </div>
        </div>
        <div className="text-wrapper">
          <p>
            {props.possibleImpact} {props.impactUnit} {props.description}
          </p>
        </div>
        <div className="link-wrapper">
          <a href={props.link} target="_blank" rel="noreferrer">
            More Info <img src={shareIcon} alt="shareIcon" />
          </a>
        </div>
      </div>
    </div>
  )
}
