import GroupedGiveUpGiveBetter from '../GroupedGiveUpGiveBetter'

export default function NameCard(props: {
  profile: any
  name: string
  cityAndCaption: string
  totalCount: Number
  chosenAct: any
  charityFor: any
  chosenActDes: string
  charityForDes: string
  country: any
  className: any
}) {
  return (
    <div className={props.className}>
      <div className="namecardouter">
        <div className="namecard-wrapper">
          <div className="profiletext-wrapper">
            <div className="profile-wrapper">
              <img src={props.profile} alt="profile" />
            </div>
            <div className="textbox-wrapper">
              <p>
                {props.name}'s pledge will help {props.cityAndCaption}
              </p>
            </div>
          </div>
          <div className="bottom-wrapper">
            <GroupedGiveUpGiveBetter
              totalCount={props.totalCount}
              chosenAct={props.chosenAct}
              charityFor={props.charityFor}
              chosenActDes={props.chosenActDes}
              charityForDes={props.charityForDes}
              country={props.country}
            />
          </div>
        </div>
        <div className="blurarrowright"></div>
      </div>
    </div>
  )
}
