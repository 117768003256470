import { useState } from 'react'

export function useCampaign() {
  const [campaignName, setCampaignName] = useState('')
  const [campaignID, setCampaignID] = useState(Number)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [billingCycle, setBillingCycle] = useState('')
  const [firstPayment, setFirstPayment] = useState(new Date())
  const [numberofActiveCampaign, setNumberofActiveCampaign] = useState(Number)
  const [activeCampaignIDs, setActiveCampaignIDs] = useState([])
  const [activeCampaignCountries, setActiveCampaignCountries] = useState([])
  const [remainingWeek, setRemainingWeek] = useState(Number)
  const [activeCampaignNames, setActiveCampaignNames] = useState([])
  const [campaignIsActive, setCampaignIsActive] = useState(false)

  const getCampaign = async (country: String) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/campaign/closest/${country}`
      )
      const data = await response.json()
      setCampaignID(data.id)
      setCampaignName(data.campaign_name)
      setStartDate(new Date(data.start_date))
      setFirstPayment(new Date(data.billing_start_date))
      const secondPayment = new Date(
        new Date(data.billing_start_date).getTime() + 7 * 24 * 60 * 60 * 1000
      )
      const thirdPayment = new Date(
        new Date(data.billing_start_date).getTime() + 14 * 24 * 60 * 60 * 1000
      )
      const fourthPaymentDate = new Date(
        new Date(data.billing_start_date).getTime() + 21 * 24 * 60 * 60 * 1000
      )
      const currentDate = new Date()
      const paymentDates = [
        new Date(data.billing_start_date),
        secondPayment,
        thirdPayment,
        fourthPaymentDate,
      ]
      let num = 0
      for (const days of paymentDates) {
        if (days >= currentDate) {
          num++
        }
      }
      setRemainingWeek(num)
      setEndDate(new Date(data.end_date))
      setFirstPayment(new Date(data.billing_start_date))
      setBillingCycle(data.billing_cycle)
      setCampaignIsActive(data.is_active)
    } catch (error) {
      console.log('Error:', error)
      setCampaignIsActive(false)
    }
  }

  const getActiveCampaign = async (country: String) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/campaign/active/${country}`
      )
      const data = await response.json()
      setNumberofActiveCampaign(data.length)
    } catch (e) {
      console.log(e)
    }
  }
  const activeCampaigns = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/active`)
        .then((response) => response.json())
        .then((data) => {
          setActiveCampaignIDs(data.map((item: any) => item.id))
          setActiveCampaignCountries(data.map((item: any) => item.country))
          setActiveCampaignNames(data.map((item: any) => item.campaign_name))
        })
    } catch (error) {
      console.log('Error:', error)
    }
  }

  return {
    campaignID,
    campaignName,
    startDate,
    endDate,
    firstPayment,
    billingCycle,
    numberofActiveCampaign,
    getCampaign,
    getActiveCampaign,
    activeCampaignIDs,
    activeCampaignCountries,
    remainingWeek,
    activeCampaignNames,
    activeCampaigns,
    campaignIsActive,
    setCampaignIsActive,
  }
}
