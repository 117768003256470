import MultiplySymbol from '../../images/multiply_vector.svg'

export default function PotentialCalculator(props: { currencySymbol: string }) {
  return (
    <div className="calculator-slider">
      <div className="calculator-slides">
        <div className="slide-calculator">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;5 coffees per week</p>
          <img src={MultiplySymbol} alt="multiply-symbol"></img>&nbsp;{props.currencySymbol}
          3.50 a coffee
          <hr className="solid"></hr>
          <p className="emphasize">
            <span style={{ color: '#94C4DA' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;{props.currencySymbol}70
            </span>{' '}
            of impact!
          </p>
        </div>
        <div className="slide-calculator">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;2 takeaways per week</p>
          <img src={MultiplySymbol} alt="multiply-symbol"></img>&nbsp;{props.currencySymbol}
          20 per takeaway
          <hr className="solid"></hr>
          <p className="emphasize">
            <span style={{ color: '#94C4DA' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;{props.currencySymbol}160
            </span>{' '}
            of impact!
          </p>
        </div>
        <div className="slide-calculator">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;8-km one-way trip to work</p>
          <img src={MultiplySymbol} alt="multiply-symbol"></img>&nbsp;10 times a week
          <hr className="solid"></hr>
          <p className="emphasize">
            <span style={{ color: '#94C4DA' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;{props.currencySymbol}120
            </span>{' '}
            of impact!
          </p>
        </div>
        <div className="slide-calculator">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;6 lunches per month</p>
          <img src={MultiplySymbol} alt="multiply-symbol"></img>&nbsp;{props.currencySymbol}
          15 per lunch
          <hr className="solid"></hr>
          <p className="emphasize">
            <span style={{ color: '#94C4DA' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;{props.currencySymbol}90
            </span>{' '}
            of impact!
          </p>
        </div>
      </div>
    </div>
  )
}
