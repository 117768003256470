import { Button } from '@mui/material'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import ReactLoading from 'react-loading'
import '../../css/authorization.css'

export const ActivationPage = () => {
  const history = useHistory()
  const [activationToken, setActivationToken] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()

  const activate = async () => {
    setIsLoading(true)

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/auth/activate?activation_token=${activationToken}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (response.ok) {
      history.push('/login')
      setIsLoading(false)
      setProcessing(true)
    } else {
      setError('tokenError', { message: 'Not a valid token' })
      setIsLoading(false)
    }
  }

  const onKeyEnter = (event: any) => {
    if (event.keyCode === 13) {
      activate()
    }
  }

  const onMousePress = (event: any) => {
    if (event.button === 0) {
      activate()
    }
  }

  if (isLoading === true) {
    return (
      <div className="loader">
        <ReactLoading type={'bars'} color={'#67b4d6'} /> Loading
      </div>
    )
  }

  return (
    <div className="Form-wrapper" style={{ marginBottom: '15.625em' }}>
      <div className="Form" style={{ textAlign: 'left' }}>
        <h1>Activate</h1>
        <br />
        <form onSubmit={handleSubmit(activate)}>
          <div style={{ display: 'flex', flexFlow: 'column', paddingBottom: '1em' }}>
            <label style={{ marginBottom: '5px' }}>Activation Token</label>
            <input
              type="password"
              value={activationToken}
              onChange={(e) => setActivationToken(e.target.value)}
              onKeyDown={onKeyEnter}
            />
            <label className="from-label">
              *Please check your email for your activation token.
            </label>
            <p style={{ color: 'red' }}>{errors.tokenError?.message}</p>
          </div>
          <div className="button-wrapper">
            <Button
              type="submit"
              disabled={processing}
              onKeyDown={onKeyEnter}
              onMouseDown={onMousePress}
            >
              Activate
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
