import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useHistory, Link, Redirect } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { useForm } from 'react-hook-form'
import '../../css/viewPledge.css'

export const LoginPage = () => {
  const auth = useAuth()
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [pledgeStatus, setPledgeStatus] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [token, setToken] = useState<any>()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  const { handleSubmit } = useForm()

  const login = async () => {
    setProcessing(true)
    await auth.login(email, password)
    setProcessing(false)
  }

  useEffect(() => {
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated))
  }, [token, isAuthenticated])

  useEffect(() => {
    const checkPledgeStatus = async () => {
      setToken(auth.accessToken)
      setIsAuthenticated(auth.isAuthenticated)
      fetch(`${process.env.REACT_APP_API_URL}/api/pledge/closest`, {
        headers: {
          Authorization: `${auth.accessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'No pledges!') {
            setPledgeStatus('Cancelled')
          } else if (data.message !== 'No pledges!') {
            setPledgeStatus(data.pledge_status)
          }
        })
    }
    if (auth.isAuthenticated) {
      checkPledgeStatus()
    }
  }, [auth.accessToken, auth.isAuthenticated])

  if (auth.isAuthenticated) {
    if (pledgeStatus === 'Confirmed') {
      return <Redirect to="/summary" />
    } else if (pledgeStatus === 'Pending') {
      return <Redirect to="/challenge?step=4" />
    } else if (pledgeStatus === 'Cancelled') {
      return <Redirect to="/challenge" />
    }
  }

  const onEnterKey = (event: any) => {
    if (event.keyCode === 13) {
      handleSubmit(login)
    }
  }

  const mouseEnter = (event: any) => {
    event.target.style.color = '#37769b'
  }

  const mouseLeave = (event: any) => {
    event.target.style.color = '#4990B4'
  }

  return (
    <div style={{ marginBottom: '6.25em' }}>
      <p className="p">
        To view your pledge progress or make changes to it,
        <br />
        please enter the details you signed up with
      </p>

      <form onSubmit={handleSubmit(login)}>
        <div className="login-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className="login-form"
            style={{ justifyContent: 'end', flexDirection: 'column', width: '453px' }}
          >
            <br />
            <div style={{ display: 'flex', flexFlow: 'column', paddingBottom: '20px' }}>
              <input
                style={{ height: '50px' }}
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexFlow: 'column' }}>
              <input
                style={{ height: '50px' }}
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={onEnterKey}
              />
            </div>
            <p style={{ color: 'red', textAlign: 'left' }}>{auth.errors.invalidLogin?.message}</p>
            <Link
              to="/activate"
              style={{ display: 'flex', paddingBottom: '10px', color: '#88bed6' }}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
            >
              Haven't activated your account? Activate now
            </Link>
            <Link
              to="/password-recovery"
              style={{ display: 'flex', paddingBottom: '30px', color: '#88bed6' }}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
            >
              Forgot your password? Click here to recover it
            </Link>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                width: '100px',
                margin: '0 auto',
                paddingBottom: '20px',
              }}
            >
              <Button type="submit" disabled={processing} onKeyPress={onEnterKey}>
                Login
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                width: '100px',
                margin: '0 auto',
              }}
            >
              <Button onClick={() => history.push('/challenge')}> Register</Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
