import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { useAuth } from '../../hooks/useAuth'
import { Country } from '../../types/enums'
import GroupedGiveUpGiveBetter from '../GroupedGiveUpGiveBetter'
import { CardBrandSwitcher } from '../Reusable/CardBrandSwitcher'
import DateFormat from '../Reusable/DateFormat'

export const PaymentForm = (necessaryInfo: any) => {
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState(String)
  const auth = useAuth()
  const [possibleImpact, setPossibleImpact] = useState(0)
  const [recipientImpactUnitPlural, setRecipientImpactUnitPlural] = useState('')
  const [allPaymentMethodInfo, setAllPaymentMethodInfo] = useState([] as any)
  const [paymentMethod, setPaymentMethod] = useState('New card')
  const [selectSavedPaymentMethod, setSelectSavedPaymentMethod] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState('')
  let costPerWeek = Number(necessaryInfo.totalAmount)

  const calculateImpact = useCallback(() => {
    if (necessaryInfo.recipientName === 'Youthline') {
      let impact = Number(
        ((necessaryInfo.totalAmount / necessaryInfo.recipientImpactCost) * 7).toFixed(0)
      )
      setPossibleImpact(impact)
    } else {
      let impact = Number(
        (necessaryInfo.totalAmount / necessaryInfo.recipientImpactCost).toFixed(0)
      )
      setPossibleImpact(impact)
    }
  }, [necessaryInfo.recipientImpactCost, necessaryInfo.recipientName, necessaryInfo.totalAmount])

  const addPlural = useCallback(() => {
    if (
      (necessaryInfo.recipientName === 'Youthline' && possibleImpact >= 2) ||
      possibleImpact === 0
    ) {
      setRecipientImpactUnitPlural(necessaryInfo.recipientImpactUnit + 's free telephone time')
    } else if (possibleImpact >= 2 || possibleImpact === 0) {
      setRecipientImpactUnitPlural(necessaryInfo.recipientImpactUnit + 's')
    } else {
      setRecipientImpactUnitPlural(necessaryInfo.recipientImpactUnit)
    }
  }, [necessaryInfo.recipientName, necessaryInfo.recipientImpactUnit, possibleImpact])

  useEffect(() => {
    calculateImpact()
    addPlural()
  }, [necessaryInfo.totalAmount, calculateImpact, addPlural])

  const [isLoading, setLoading] = useState(true)
  let newPaymentMethodId: any

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const token = auth.accessToken
    const Confirmsetup = async () => {
      if (!selectSavedPaymentMethod) {
        try {
          const { error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
          })
          if (error) {
            setTimeout(() => {
              if (
                error.type !== 'validation_error' &&
                error.message &&
                errorMessage !== error.message
              ) {
                setErrorMessage(error.message)
              } else {
                setErrorMessage('')
              }
              setLoading(false)
            }, 1000)
          } else {
            setTimeout(() => {
              stripe.retrieveSetupIntent(necessaryInfo.clientSecret).then((data) => {
                newPaymentMethodId = data.setupIntent?.payment_method
                console.log(newPaymentMethodId)

                postPaymentMethodID(newPaymentMethodId).then(() => {
                  confirmPledge()
                  routeChange()
                })
              })
            }, 1000)
          }
        } catch (e) {
          console.log(e, isLoading)
        }
      } else {
        if (paymentMethodId !== '') {
          setTimeout(() => {
            postPaymentMethodID(paymentMethodId).then(() => {
              confirmPledge()
              routeChange()
            })
          }, 1000)
        } else {
          setErrorMessage('Please select a payment method')
        }
      }
    }

    Confirmsetup()

    const routeChange = () => {
      history.push('/SubmitPage')
    }

    const postPaymentMethodID = async (paymentMethodId: any) => {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/api/payment_method`, {
          method: 'POST',
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            method_name: 'card',
            is_enabled: true,
            stripe_payment_method_id: paymentMethodId,
          }),
        }).then((response) => response.json())
      } catch (e) {
        console.log(e)
      }
    }

    const confirmPledge = async () => {
      if (paymentMethodId !== '') {
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/confirmed`, {
            method: 'PUT',
            headers: {
              Authorization: `${auth.accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              payment_method_id: paymentMethodId,
            }),
          }).then((response) => response.json())
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/confirmed`, {
            method: 'PUT',
            headers: {
              Authorization: `${auth.accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              payment_method_id: newPaymentMethodId,
            }),
          }).then((response) => response.json())
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  let parseCurrencySymbol = necessaryInfo.country === 'NZ' ? '$' : '£'
  let country = Country[necessaryInfo.country]
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    console.log(errorMessage)
    setErrorMessage('')
  }
  const currentDay = new Date()
  const pre_paymentDates = [
    necessaryInfo.billingStartDate,
    necessaryInfo.secondPaymentDate,
    necessaryInfo.thirdPaymentDate,
    necessaryInfo.fourthPaymentDate,
  ]
  let remainingDays = 0
  let paymentDate = []
  for (let i = 0; i < pre_paymentDates.length; i++) {
    if (pre_paymentDates[i] >= currentDay) {
      remainingDays++
      paymentDate.push(pre_paymentDates[i])
    }
  }

  const firstPayment = paymentDate[0]
  costPerWeek = costPerWeek / remainingDays

  const getCardInformation = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/payment_method`, {
        headers: {
          Authorization: `${auth.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          let paymentMethodData: any = []
          for (let i = 0; i < data.length; i++) {
            paymentMethodData.push({
              paymentMethodId: data[i].id,
              brand: data[i].card.brand,
              last4Digits: data[i].card.last4,
              expiryMonth: data[i].card.exp_month,
              expiryYear: data[i].card.exp_year,
            })
          }
          setAllPaymentMethodInfo(paymentMethodData)
        })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getCardInformation()
  }, [auth.accessToken])

  const savedPaymentRadio = () => {
    return (
      <>
        <FormControl>
          {allPaymentMethodInfo.length > 0 ? (
            <RadioGroup
              defaultValue={allPaymentMethodInfo[0].paymentMethodId}
              onLoad={() => {
                setPaymentMethodId(allPaymentMethodInfo[0].paymentMethodId)
              }}
              onChange={handleSavedPaymentMethod}
            >
              {allPaymentMethodInfo.map((item: any) => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel
                      style={{
                        fontSize: '10px',
                      }}
                      value={item.paymentMethodId}
                      control={<Radio />}
                      label={`**** **** **** ${item.last4Digits} ${item.expiryMonth}/${item.expiryYear}`}
                    />
                    <img
                      src={CardBrandSwitcher(item.brand)}
                      alt={item.brand}
                      style={{
                        width: '40px',
                        marginLeft: '-10px',
                        marginTop: '-8px',
                      }}
                    />
                  </div>
                )
              })}
            </RadioGroup>
          ) : (
            <div style={{ color: 'red', fontSize: '16px' }}>No saved payment methods</div>
          )}
        </FormControl>
      </>
    )
  }

  const handleSavedPaymentMethod = (event: any) => {
    setPaymentMethodId(event.target.value)
  }

  const handlePaymentChange = (event: any) => {
    setPaymentMethod(event.target.value)

    if (event.target.value === 'Saved card') {
      setSelectSavedPaymentMethod(true)
    } else {
      setSelectSavedPaymentMethod(false)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="payment-info">
          <div className="paymentInput">
            <span style={{ marginBottom: '20px' }}>Payment details</span>
            <Box sx={{ minWidth: 100, maxWidth: 520, marginBottom: '1em', fontSize: 13 }}>
              <FormControl fullWidth>
                <InputLabel>Select a Payment Method</InputLabel>
                <Select
                  defaultValue={paymentMethod}
                  value={paymentMethod}
                  label="PaymentMethod"
                  onChange={handlePaymentChange}
                >
                  <MenuItem value="Saved card">Use a Saved Card</MenuItem>
                  <MenuItem value="New card">Use a New Card</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {selectSavedPaymentMethod ? savedPaymentRadio() : <PaymentElement />}
          </div>
          <div className="paymentDes-wrapper">
            <span style={{ marginBottom: '20px' }}>Pledge Summary</span>
            <p className="paymentDes">
              You'll be charged {parseCurrencySymbol}
              {costPerWeek} {necessaryInfo.country === 'NZ' ? 'NZD' : 'GBP'} once a week for the
              month of {necessaryInfo.month}, for a total of {parseCurrencySymbol}
              {Number(necessaryInfo.totalAmount).toFixed(2)}{' '}
              {necessaryInfo.country === 'NZ' ? 'NZD' : 'GBP'}. The payments will be on the{' '}
              {pre_paymentDates.map((items, key) => {
                if (items >= currentDay) {
                  if (key == pre_paymentDates.length - 1) {
                    return <>{DateFormat(items.getDate())}. </>
                  }
                  return <>{DateFormat(items.getDate())}, </>
                }
              })}
            </p>
            <GroupedGiveUpGiveBetter
              totalCount={necessaryInfo.totalAmount}
              chosenAct={necessaryInfo.challengeLogo}
              charityFor={necessaryInfo.recipientLogo}
              chosenActDes={`${remainingDays} ${necessaryInfo.challengeUnit}`}
              charityForDes={`${possibleImpact} ${recipientImpactUnitPlural}`}
              country={country}
            />
          </div>
          <p className="chargeDes">
            We won't charge anything to your card until {firstPayment.getDate()}/
            {firstPayment.getMonth() + 1}/{firstPayment.getFullYear()}. You can cancel your pledge
            at any time.
          </p>
        </div>
        <button
          className="submit"
          disabled={!stripe}
          onClick={() => setTimeout(() => setOpen((o) => !o), 1000)}
          style={{ cursor: 'pointer', marginBottom: '20px' }}
        >
          Submit Pledge
        </button>
        {errorMessage ? (
          <Popup modal nested open={open} closeOnDocumentClick onClose={handleClose}>
            <h3 style={{ color: '#df1b41', margin: '20px' }}>{errorMessage}</h3>
          </Popup>
        ) : (
          <>
            <Popup modal nested open={false} closeOnDocumentClick onClose={handleClose}>
              <h3 style={{ color: '#df1b41', margin: '20px' }}>{errorMessage}</h3>
            </Popup>
          </>
        )}
      </form>
    </>
  )
}
