import BetterForUsIcon from '../../images/better_for_us_icon.svg'
import SupportLocal from '../../images/support_local.svg'
import Globe from '../../images/globe.svg'

export default function SoWhyGiveBetter() {
  return (
    <div className="whyGiveBetter-landing-wrapper">
      <div className="landing-background">
        <div className="h1-section">
          <h1>So, why Give Better?</h1>
        </div>
        <div className="why-gb-wrapper">
          <div className="why-gb-box">
            <img src={Globe} className="why-gb-icon" alt="globe" />
            <h3 className="why-gb-h3">It's better for the world</h3>
            <p className="why-gb-content">
              Thousands of dollars freed up from everyday spending and given to organisations that
              are changing the world.
            </p>
          </div>
          <div className="why-gb-box">
            <img src={BetterForUsIcon} className="why-gb-icon" alt="Better for us" />
            <h3 className="why-gb-h3">It's better for us</h3>
            <p className="why-gb-content">
              Grow your financial awareness, feel more in control of your spending, and discover why
              science tells us there is sometimes more joy in giving than spending on ourselves.
            </p>
          </div>
        </div>
        <div className="why-gb-bottom">
          <img src={SupportLocal} className="support-local-icon" alt="Support local" />
          <h3 className="why-gb-h3"> We support local businesses</h3>
          <p className="why-gb-content">
            Small, local businesses need our help right now. So Give Better encourages 'robin hood'
            challenges. That means giving up spending that would otherwise go to those corporate fat
            cats who really don't need it. You know who I mean, streaming services, coffee chains,
            car parking tycoons...
          </p>
        </div>
      </div>
    </div>
  )
}
