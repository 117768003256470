import { useEffect, useState } from 'react'

export default function CoffeeCalculator(props: {
  itemChosen: string
  totalAmount: Number
  totalTimes: Number
  perCost: Number
  weeklyAmount: Number
  back: Number
  remainingWeek: number
}) {
  const [times, setTimes] = useState(0)
  const [perCost, setPerCost] = useState(0)
  const [weeklyCost, setWeeklyCost] = useState(0)
  const [total, setTotal] = useState(0)
  const [frequency, setFrequency] = useState('weekly')
  const weeklyChange = (event: any) => {
    if (event.target.value === 'monthly') {
      setFrequency(event.target.value)
      PubSub.publish('total', ((times * perCost) / 4) * props.remainingWeek)
      PubSub.publish('weeklyAmount', total / props.remainingWeek)
    } else if (event.target.value === 'weekly') {
      setFrequency(event.target.value)
      PubSub.publish('total', times * perCost * props.remainingWeek)
      PubSub.publish('weeklyAmount', times * perCost)
    }
  }
  useEffect(() => {
    setTotal(times * perCost * props.remainingWeek)
    setWeeklyCost(total / props.remainingWeek)
    PubSub.publish('total', total)
    if (frequency === 'weekly') {
      PubSub.publish('times', times * props.remainingWeek)
    } else if (frequency === 'monthly') {
      PubSub.publish('times', Number(((times / 4) * props.remainingWeek).toFixed(0)))
    }
    PubSub.publish('weeklyAmount', weeklyCost)
  }, [times, total, weeklyCost])

  const updateTimes = (event: any) => {
    const value = event.target.value * 1
    if (value.toString() === event.target.value && value === parseInt(event.target.value)) {
      setTimes(value)
    } else {
      setTimes(0)
      PubSub.publish('total', 0)
    }
  }

  useEffect(() => {
    if (frequency === 'monthly') {
      setTotal(((times * perCost) / 4) * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('total', total)
      PubSub.publish('perCost', perCost)
      PubSub.publish('weeklyAmount', weeklyCost)
    } else if (frequency === 'weekly') {
      setTotal(times * perCost * props.remainingWeek)
      setWeeklyCost(total / props.remainingWeek)
      PubSub.publish('total', total)
      PubSub.publish('perCost', perCost)
      PubSub.publish('weeklyAmount', weeklyCost)
    }
  }, [perCost, total, weeklyCost])

  const updateCost = (event: any) => {
    let value = event.target.value * 1

    if (frequency === 'monthly') {
      setPerCost(value)
    } else if (frequency === 'weekly') {
      setPerCost(value)
    } else {
      PubSub.publish('total', 0)
    }
  }

  return (
    <>
      <div className="calculator-body">
        <h2>Your spending</h2>
        <div className="calculator-radio-wrapper">
          <input
            className="radio"
            type="radio"
            value="weekly"
            name="spending"
            onChange={weeklyChange}
            defaultChecked
          />
          &nbsp;Weekly&nbsp;&nbsp;
          <input
            className="radio"
            type="radio"
            value="monthly"
            name="spending"
            onChange={weeklyChange}
          />
          &nbsp;Monthly
        </div>
        <div className="times-wrapper">
          <span>How often do you buy {props.itemChosen}?</span>
          <div>
            <input
              className="text-box"
              type="text"
              name="how many"
              onChange={updateTimes}
              placeholder={props.back === 0 ? 'e.g. 1' : String(props.totalTimes)}
              pattern="^\d+$"
            />
          </div>
        </div>
        <div className="perCost-wrapper">
          <span>How much do you spend on average?</span>
          <div>
            <input
              className="text-box"
              type="text"
              name="how much"
              onChange={updateCost}
              placeholder={props.back === 0 ? 'e.g. 0.00' : String(perCost)}
              pattern="^\d+(\.)?(\d)*\d*$"
            />
          </div>
        </div>
      </div>
    </>
  )
}
