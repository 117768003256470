import '../../css/onboarding.css'

export default function GroupedCampaigns(props: {
  campaignID: Number
  campaignCountry: string
  campaignName: string
  campaignFlag: any
  ifSelected: boolean
  id: string
  updateSelect(id: string, select: boolean, campaignCountry: string, campaignID: Number): any
}) {
  const display = () => {
    props.updateSelect(props.id, !props.ifSelected, props.campaignCountry, props.campaignID)
  }
  return (
    <div onClick={display} className="campaign-wrapper">
      <div
        className={['campaign-innerWrapper', props.ifSelected === true ? 'selected' : null].join(
          ' '
        )}
      >
        <div className="campaign-country">
          {props.campaignCountry} {props.campaignName}
        </div>
        <div className="campaign-logo-wrapper">
          <img src={props.campaignFlag} alt="logo" />
        </div>
      </div>
    </div>
  )
}
