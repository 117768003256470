import '../css/summary.css'
import '../css/popup.css'
import 'reactjs-popup/dist/index.css'

import { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Popup from 'reactjs-popup'

import { useAuth } from '../hooks/useAuth'
import { Country } from '../types/enums'
import Calendar from '../components/SummaryPage/Calendar'
import GroupedGiveUpGiveBetter from '../components/GroupedGiveUpGiveBetter'
import CancelPage from '../components/SummaryPage/CancelPage'
import { CardBrandSwitcher } from '../components/Reusable/CardBrandSwitcher'

export default function SummaryPledge() {
  const auth = useAuth()
  const [name, setNames] = useState(String)
  const [campaignStartDate, setCampaignStartDate] = useState(new Date())
  const [campaignBillingDate, setCampaignBillingDate] = useState(new Date())
  const [remainingDays, setRemainingDays] = useState(Number)
  const [campaignCountry, setCampaignCountry] = useState(Country.NZ)
  const [challengeDuration, setChallengeDuration] = useState(Number)
  const [charityLogo, setCharityLogo] = useState(String)
  const [charityName, setCharityName] = useState(String)
  const [impactCost, setImpactCost] = useState(Number)
  const [impactUnit, setImpactUnit] = useState(String)
  const [impactUnitFormat, setImpactUnitFormat] = useState(String)
  const [pledgeImpact, setPledgeImpact] = useState(Number)
  const [iconDesc, setIconDesc] = useState(String)
  const [givingUpIcon, setGivingUpIcon] = useState(String)
  const [confirmedAmount, setConfirmedAmount] = useState(Number)
  const [impactSoFar, setImpactSoFar] = useState(Number)
  const [nextPaymentAmount, setNextPaymentAmount] = useState(Number)
  const [nextPaymentDate, setNextPaymentDate] = useState(new Date())
  const [lastPaymentDate, setLastPaymentDate] = useState(new Date())
  const [remainingPayment, setRemainingPayment] = useState(Number)
  const [cardLast4Digits, setCardLast4Digits] = useState('')
  const [expiryMonth, setExpiryMonth] = useState('')
  const [expiryYear, setExpiryYear] = useState('')
  const [bankBrand, setBankBrand] = useState('')

  let token: any

  const history = useHistory()
  let isAuthenticated = auth.isAuthenticated

  const loginAuth = localStorage.getItem('isAuthenticated')
  if (loginAuth !== null) {
    isAuthenticated = JSON.parse(loginAuth)
  }

  if (
    history.location.pathname === '/summary' &&
    localStorage.length !== 0 &&
    isAuthenticated !== false
  ) {
    const loginToken = localStorage.getItem('token')
    if (loginToken !== null) {
      token = JSON.parse(loginToken)
    }
  } else if (
    (history.location.pathname === '/summary' && localStorage.length === 0) ||
    (history.location.pathname === '/summary' && isAuthenticated === false)
  ) {
    history.replace('/login')
  }

  const fetchUser = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/user_info`, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      setNames(data.first_name)
    } catch (error) {
      console.log('Error:', error)
    }
  }, [token])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  const getCardDetails = useCallback(async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/payment/`, {
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          let paymentMethodId = data[0].stripe_payment_method_id

          return Promise.all([
            fetch(`${process.env.REACT_APP_API_URL}/api/payment_method/${paymentMethodId}`, {})
              .then((res) => res.json())
              .then((data) => {
                setBankBrand(data.card.brand)
                setCardLast4Digits(data.card.last4)
                setExpiryMonth(data.card.exp_month)
                setExpiryYear(data.card.exp_year)
              }),
          ])
        })
    } catch (error) {
      console.log('Error:', error)
    }
  }, [token])

  useEffect(() => {
    getCardDetails()
  }, [getCardDetails])

  const fetchDate = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaign/closest`, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      const today = new Date()
      const campaignFinish = new Date(data.end_date)
      const diffTime = Math.ceil(
        (campaignFinish.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
      )
      setRemainingDays(diffTime)

      setCampaignBillingDate(new Date(data.billing_start_date))
      setCampaignStartDate(new Date(data.start_date))

      const campaignCountry = data.country
      if (campaignCountry === 'NZ') {
        setCampaignCountry(Country.NZ)
      } else if (campaignCountry === 'UK') {
        setCampaignCountry(Country.UK)
      }
    } catch (e) {
      console.log(e)
    }
  }, [token])

  useEffect(() => {
    fetchDate()
  }, [fetchDate])

  const fetchPledge = useCallback(async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/closest/Confirmed`, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((pledge) => pledge.json())
        .then((data) => {
          const recipientId = data.recipient_id
          const challengeId = data.challenge_id
          setConfirmedAmount(Number(data.confirmed_amount))
          return Promise.all([
            fetch(`${process.env.REACT_APP_API_URL}/api/recipient/${recipientId}`)
              .then((value) => value.json())
              .then((recipient) => {
                setCharityLogo(recipient.logo)
                setImpactCost(recipient.impact_cost)
                setImpactUnit(recipient.impact_unit)
                setCharityName(recipient.recipient_name)
              }),
            fetch(`${process.env.REACT_APP_API_URL}/api/challenge/${challengeId}`)
              .then((value) => value.json())
              .then((challenge) => {
                setGivingUpIcon(challenge.landing_page_icon)
                setIconDesc(challenge.unit)
              }),
          ])
        })
    } catch (e) {
      console.log(e)
    }
  }, [token])

  useEffect(() => {
    fetchPledge()
  }, [fetchPledge])

  const calculateImpact = useCallback(() => {
    if (charityName === 'Youthline') {
      let impact = Number(((confirmedAmount / Number(impactCost)) * 7).toFixed(0))
      setPledgeImpact(impact)
    } else {
      let impact = Number(confirmedAmount / Number(impactCost)).toFixed(0)
      setPledgeImpact(Number(impact))
    }
  }, [charityName, confirmedAmount, impactCost])

  useEffect(() => {
    calculateImpact()
  }, [charityName, calculateImpact])

  const formatUnit = useCallback(() => {
    if (charityName === 'Youthline' && (pledgeImpact >= 2 || pledgeImpact === 0)) {
      setImpactUnitFormat(impactUnit + 's free telephone time')
    } else if (pledgeImpact >= 2 || pledgeImpact === 0) {
      setImpactUnitFormat(impactUnit + 's')
    } else {
      setImpactUnitFormat(impactUnit)
    }
  }, [charityName, impactUnit, pledgeImpact])

  useEffect(() => {
    formatUnit()
  }, [calculateImpact, charityName, formatUnit])

  const fetchPayments = useCallback(async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/payment/`, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((value) => value.json())
        .then((payments: Array<Payments>) => {
          let impactSoFar = 0

          payments.forEach((payment) => {
            if (payment.payment_status === 'Success') {
              impactSoFar += Number(payment.amount)
            }
          })
          setImpactSoFar(impactSoFar)

          let remainingPending = 0
          payments.forEach((payment) => {
            if (payment.payment_status === 'Pending') {
              remainingPending++
            }
          })
          setRemainingPayment(remainingPending)
          const pendingPayment = payments.find(
            (payment: Payments) => payment.payment_status === 'Pending'
          )
          setChallengeDuration(payments.length)
          if (pendingPayment) {
            setNextPaymentDate(new Date(pendingPayment.processing_date))
            setNextPaymentAmount(Number(pendingPayment.amount))
            const lastPayment = payments.pop()
            if (lastPayment) {
              setLastPaymentDate(new Date(lastPayment.processing_date))
            }
          }
        })
    } catch (e) {
      console.log(e)
    }
  }, [token])

  useEffect(() => {
    fetchPayments()
  }, [fetchPayments])

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const day = 24 * 60 * 60 * 1000 //does not account for daylights
  const secondPaymentDate = new Date(campaignBillingDate.getTime() + 7 * day)
  const thirdPaymentDate = new Date(campaignBillingDate.getTime() + 14 * day)
  const fourthPaymentDate = new Date(campaignBillingDate.getTime() + 21 * day)

  return (
    <>
      <div className="caption">
        <div className="inner-caption">
          <h1>Hello {name}, here's your pledge summary.</h1>
          <h2>Your progress - {remainingDays} days to go</h2>
          <h3>
            Your impact so far is {campaignCountry === Country.NZ ? '$' : '£'}
            {impactSoFar.toFixed(2)}, thank you!
          </h3>
          <div className="calendar-giveBetter-wrapper">
            <Calendar
              firstPayment={campaignBillingDate}
              secondPayment={secondPaymentDate}
              thirdPayment={thirdPaymentDate}
              lastPayment={fourthPaymentDate}
              campaignStartDate={campaignStartDate}
            />
            <GroupedGiveUpGiveBetter
              totalCount={confirmedAmount}
              chosenAct={givingUpIcon}
              charityFor={charityLogo}
              chosenActDes={`${challengeDuration} ${iconDesc}`}
              charityForDes={`${pledgeImpact} ${impactUnitFormat}`}
              country={campaignCountry}
            />
          </div>
          <h2>Payment</h2>
          <h4>
            Your next payment of{' '}
            <b>
              {campaignCountry === Country.NZ ? '$' : '£'}
              {nextPaymentAmount}
            </b>{' '}
            will be on{' '}
            <b>
              {nextPaymentDate.getDate()}/{nextPaymentDate.getMonth() + 1}/
              {nextPaymentDate.getFullYear()}
            </b>
            .
          </h4>
          <h4>
            Your last weekly payment will be on {fourthPaymentDate.getDate()}/
            {fourthPaymentDate.getMonth() + 1}/{fourthPaymentDate.getFullYear()}
          </h4>
          <h4 style={{ fontWeight: '600', marginTop: '24px', fontSize: '18px' }}>Payment method</h4>
          <div className="payment-detail-wrapper">
            <img src={CardBrandSwitcher(bankBrand)} alt="brandLogo" style={{ width: '80px' }} />
            <span>**** **** **** {cardLast4Digits}</span>
            <p>
              Expiry date: {expiryMonth}/{expiryYear}
            </p>
          </div>
          <span
            style={{
              textAlign: 'left',
              color: '#BB4C4C',
              cursor: 'pointer',
              marginTop: '30px',
              fontSize: '18px',
            }}
            onClick={() => setOpen((o) => !o)}
          >
            Cancel future pledge payments
          </span>
          <Popup modal nested open={open} closeOnDocumentClick onClose={handleClose}>
            <CancelPage remainingPayment={remainingPayment} name={name} />
            <button onClick={handleClose}>Go back</button>
          </Popup>
        </div>
      </div>
    </>
  )
}
