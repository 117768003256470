import { useState } from 'react'

export function usePledge() {
  const [totalPledgeByCampaign, setTotalPledgeByCampaign] = useState(0)
  const [totalPledgeAmountByCampaign, setTotalPledgeAmountByCampaign] = useState(0)
  const [totalPledgeByChallenge, setTotalPledgeByChallenge] = useState([])
  const [totalPledgeAmountByChallenge, setTotalPledgeAmountByChallenge] = useState([])
  const [totalCharityPledges, setTotalCharityPledges] = useState([])
  const [totalCharityAmount, setTotalCharityAmount] = useState([])

  const getPledgeCampaignTotal = async (country: String) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/pledge/total/campaign/${country}`
      )
      const data = await response.json()
      setTotalPledgeByCampaign(data)
    } catch (error) {
      console.log('Error:', error)
    }
  }

  const getPledgeCampaignAmount = async (country: String) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/pledge/total/campaign-amount/${country}`
      )
      const data = await response.json()
      setTotalPledgeAmountByCampaign(data)
    } catch (error) {
      console.log('Error:', error)
    }
  }

  const getPledgeChallengeTotal = async (country: String) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/total/challenge/${country}`)
        .then((response) => response.json())
        .then((data) => {
          setTotalPledgeByChallenge(data.map((item: any) => item))
        })
    } catch (error) {
      console.log('Error:', error)
    }
  }

  const getPledgeChallengeAmount = async (country: String) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/total/challenge-amount/${country}`)
        .then((response) => response.json())
        .then((data) => {
          setTotalPledgeAmountByChallenge(data.map((item: any) => item))
        })
    } catch (error) {
      console.log('Error:', error)
    }
  }

  const getTotalCharityPledges = async (country: any) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/total/recipient/` + country)
        .then((response) => response.json())
        .then((data) => {
          setTotalCharityPledges(data.map((item: any) => item))
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getTotalCharityAmount = async (country: String) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/total/recipient-amount/` + country)
        .then((response) => response.json())
        .then((data) => {
          setTotalCharityAmount(data.map((item: any) => item))
        })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    totalPledgeByCampaign,
    getPledgeCampaignTotal,
    totalPledgeAmountByCampaign,
    getPledgeCampaignAmount,
    totalPledgeByChallenge,
    getPledgeChallengeTotal,
    totalPledgeAmountByChallenge,
    getPledgeChallengeAmount,
    totalCharityPledges,
    getTotalCharityPledges,
    totalCharityAmount,
    getTotalCharityAmount,
  }
}
