import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '../css/slide.scss'

export default function SimpleSlider(props: { topDesc: any[]; logo: any[]; bottomDesc: any[] }) {
  const settings = {
    infinite: true,
    autoplay: true,
    dots: true,
    pauseOnHover: true,
    speed: 4200,
    slidesToShow: 1,
  }

  let displayItems

  if (props.topDesc.length > 0) {
    displayItems = props.topDesc.map((item, index) => {
      return [item, props.logo[index], props.bottomDesc[index]]
    })
  }

  return (
    <div>
      <Slider {...settings}>
        {displayItems?.map((item, index) => {
          return (
            <div key={index}>
              <p style={{ margin: '0', paddingBottom: '1.5em' }}>{item[0]}</p>
              <img className="slide-img" src={item[1]} alt="logo" />
              <p style={{ margin: '0', paddingTop: '1.5em' }}>{item[2]}</p>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
