import { useEffect, useState } from 'react'
import { useCampaign } from '../../hooks/campaign'
import GroupedCampaigns from './GroupedCampaigns'
import NZFlag from '../../images/Flags/NZFlag.svg'
import UKFlag from '../../images/Flags/UKFlag.svg'

export let campaignStatus: boolean
export function setCampaignStatus(tempStatus: boolean) {
  campaignStatus = tempStatus
}

export default function ChooseCampaign() {
  const activeCampaigns = useCampaign()
  const [campaignsBool, setCampaignsBool] = useState<any>([])
  const [campaignName, setCampaignName] = useState<any>([])

  useEffect(() => {
    activeCampaigns.activeCampaigns()
  }, [])

  useEffect(() => {
    let newCampaignName: Array<string>
    newCampaignName = []
    activeCampaigns.activeCampaignNames.map((item: any, key: any) => {
      newCampaignName.push(item.substring(0, 20))
      return null
    })

    setCampaignName(newCampaignName)
  }, [activeCampaigns.activeCampaignNames])

  const updateSelection = (
    id: string,
    selected: boolean,
    campaignCountry: string,
    campaignID: number
  ) => {
    const c = { [id]: selected }
    if (c[id]) {
      campaignStatus = true
    } else {
      campaignStatus = false
    }

    let newCampaignsBool: Array<boolean>
    newCampaignsBool = []
    if (campaignCountry === 'NZ' || campaignCountry === 'UK') {
      activeCampaigns.activeCampaignIDs.map((item: any, key: any) => {
        newCampaignsBool.push(false)
        return null
      })
      setCampaignsBool(newCampaignsBool)
      newCampaignsBool[Number(id)] = selected
      setCampaignsBool(newCampaignsBool)
    }

    PubSub.publish('campaignCountry', campaignCountry)
    PubSub.publish('campaignID', campaignID)
  }

  const loadCampaigns = () => {
    const list = []
    for (let i = 0; i < activeCampaigns.activeCampaignCountries.length; i++) {
      list.push(
        <GroupedCampaigns
          campaignID={activeCampaigns.activeCampaignIDs[i]}
          campaignCountry={activeCampaigns.activeCampaignCountries[i]}
          campaignName={campaignName[i]}
          campaignFlag={activeCampaigns.activeCampaignCountries[i] === 'NZ' ? NZFlag : UKFlag}
          ifSelected={campaignsBool[i]}
          id={[i].toString()}
          key={i}
          updateSelect={updateSelection}
        />
      )
    }
    return list
  }

  return (
    <div className="choose-campaign-wrapper">
      <h2>Select which country's campaign you would like to join</h2>
      <div className="stepper-content-wrapper">{loadCampaigns()}</div>
    </div>
  )
}
