import fb from '../images/mediaAppLogo/shareFB.svg'
import ins from '../images/mediaAppLogo/shareINS.svg'
import MailIcon from '@mui/icons-material/Mail'
import { grey } from '@mui/material/colors'

export default function Footer() {
  return (
    <footer>
      <div className="footer-wrapper" id="footer-link">
        <div className="footer-content">
          <p>
            &copy; 2022 Give Better Trust | <a href="/privacy-policy">Privacy Policy</a>
          </p>
          <div className="footer-contact">
            <a href="mailto:sel@givebetter.nz">
              <MailIcon
                sx={{
                  color: grey[600],
                  width: '1.70em',
                  height: '1.70em',
                  margin: '0.20em 0.20em',
                }}
              />
            </a>
            <a href="https://www.facebook.com/GiveBetterNZ" target="_blank" rel="noreferrer">
              <img src={fb} alt="logoimg"></img>
            </a>
            <a href="https://www.instagram.com/give__better/" target="_blank" rel="noreferrer">
              <img src={ins} alt="logoimg"></img>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
