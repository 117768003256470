import AmericanExpress from '../../images/bankBrand/amex_symbol.svg'
import MasterCard from '../../images/bankBrand/mc_symbol.svg'
import Visa from '../../images/bankBrand/visa_symbol.svg'
import JCB from '../../images/bankBrand/jcb_symbol.svg'
import UnionPay from '../../images/bankBrand/UnionPay-logo.png'
import PlaceHolderLogo from '../../images/bankBrand/cc_placeholder_symbol.svg'

export const CardBrandSwitcher = (brand: string) => {
  switch (brand) {
    case 'amex':
      return AmericanExpress
    case 'mastercard':
      return MasterCard
    case 'visa':
      return Visa
    case 'jcb':
      return JCB
    case 'unionpay':
      return UnionPay
    default:
      return PlaceHolderLogo
  }
}
