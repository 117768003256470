import { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useAuth } from '../../hooks/useAuth'
import { PaymentForm } from './PaymentForm'
import { Elements } from '@stripe/react-stripe-js'

function PaymentMethod(props: { country: any }) {
  const [clientsecret, SetClientSecret] = useState('')
  const [totalAmount, SetTotalAmount] = useState(Number)
  const [numberOfUnit, setNumberOfUnit] = useState(Number)
  const [recipientName, setRecipientName] = useState(String)
  const [recipientLogo, setRecipientLogo] = useState<any>()
  const [recipientImpactUnit, setRecipientImpactUnit] = useState('')
  const [recipientImpactCost, setRecipientImpactCost] = useState(Number)
  const [challengeUnit, setChallengeUnit] = useState('')
  const [challengeLogo, setChallengeLogo] = useState<any>()
  const [billingStartDate, setBillingStartDate] = useState<any>(new Date())
  const [campaignName, setCampaignName] = useState([])
  const [campaignCountry, setCampaignCountry] = useState('')

  const auth = useAuth()
  const token = auth.accessToken

  useEffect(() => {
    const getSetupID = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/payment_method/secret`, {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        })

        const data = await response.json()
        SetClientSecret(data)
      } catch (e) {
        console.log(e)
      }
    }
    getSetupID()
  }, [token])

  useEffect(() => {
    const getPledgeInfo = async () => {
      let data
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/pledge/closest`, {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        })
        data = await response.json()
        SetTotalAmount(data.confirmed_amount)
        setNumberOfUnit(data.number_of_units)

        const recipientId = data.recipient_id
        const recipientResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/recipient/${recipientId}`
        )
        const recipientData = await recipientResponse.json()
        setRecipientName(recipientData.recipient_name)
        setRecipientImpactCost(recipientData.impact_cost)
        setRecipientImpactUnit(recipientData.impact_unit)
        setRecipientLogo(recipientData.logo)

        const challengeId = data.challenge_id
        const challengeResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/challenge/${challengeId}`
        )
        const challengeData = await challengeResponse.json()
        setChallengeUnit(challengeData.unit)
        setChallengeLogo(challengeData.icon)

        const campaignId = data.campaign_id
        const campaignResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/campaign/${campaignId}`
        )
        const campaignData = await campaignResponse.json()
        setBillingStartDate(campaignData.billing_start_date)
        setCampaignName(campaignData.campaign_name.split(' '))
        setCampaignCountry(campaignData.country)
      } catch (Error) {
        console.log('Unable to fetch campaign information:', Error)
      }
    }
    getPledgeInfo()
  }, [token])

  const options = {
    clientSecret: clientsecret,
    appearance: {
      labels: 'floating' as const,
      rules: {
        '.Tab': {
          height: '47px',
          border: '1px solid #979797',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
        },
      },
    },
  }

  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)

  if (!clientsecret) {
    return <h2>loading</h2>
  } else {
    return (
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm
          token={token}
          clientSecret={clientsecret}
          challengeUnit={challengeUnit}
          totalAmount={totalAmount}
          totalTimes={numberOfUnit}
          month={campaignName[0]}
          billingStartDate={new Date(new Date(billingStartDate).getTime())}
          secondPaymentDate={
            new Date(new Date(billingStartDate).getTime() + 7 * 24 * 60 * 60 * 1000)
          }
          thirdPaymentDate={
            new Date(new Date(billingStartDate).getTime() + 14 * 24 * 60 * 60 * 1000)
          }
          fourthPaymentDate={
            new Date(new Date(billingStartDate).getTime() + 21 * 24 * 60 * 60 * 1000)
          }
          country={campaignCountry}
          recipientName={recipientName}
          recipientLogo={recipientLogo}
          recipientImpactUnit={recipientImpactUnit}
          recipientImpactCost={recipientImpactCost}
          challengeLogo={challengeLogo}
        />
      </Elements>
    )
  }
}

export default PaymentMethod
