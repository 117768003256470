import { useEffect, useState } from 'react'
import { useChallenges } from '../../hooks/challenge'
import { usePledge } from '../../hooks/pledge'
import { Country } from '../../types/enums'

export default function ChallengeDisplay(props: {
  country: Country
  currencySymbol: string
  campaignIsActive: boolean
}) {
  const countryString = props.country === Country.NZ ? 'NZ' : 'UK'
  const [challenges, setChallenges] = useState<any>([true, false, false, false, false, false])
  const challengesData = useChallenges()
  const pledgeData = usePledge()

  useEffect(() => {
    const getInfo = async () => {
      challengesData.getChallenges(countryString)
      pledgeData.getPledgeChallengeTotal(countryString)
      pledgeData.getPledgeChallengeAmount(countryString)
      challengesData.getDefaultChallenges(countryString)
    }
    getInfo()
  }, [props.country])

  const handleClick = (index: number) => {
    const newChallenges = [...challenges]
    newChallenges[index] = true
    for (let i = 0; i < newChallenges.length; i++) {
      if (i !== index) {
        newChallenges[i] = false
      }
    }
    setChallenges(newChallenges)
  }

  const checkUpdate = () => {
    if (props.campaignIsActive) {
      for (let i = 0; i < pledgeData.totalPledgeAmountByChallenge.length; i++) {
        if (challenges[i]) {
          return (
            'Join ' +
            pledgeData.totalPledgeByChallenge[i] +
            ' others pledging ' +
            props.currencySymbol +
            pledgeData.totalPledgeAmountByChallenge[i]
          )
        }
      }
    } else {
      return null
    }
  }

  const updateChallengeName = () => {
    for (let i = 0; i < challengesData.challengeNames.length; i++) {
      if (!props.campaignIsActive) {
        if (challenges[i]) {
          if (challengesData.challengeDefaultNames[i] === 'Lunches') {
            return 'Buying lunches'
          } else {
            return challengesData.challengeDefaultNames[i]
          }
        }
      } else {
        if (challenges[i]) {
          if (challengesData.challengeNames[i] === 'Lunches') {
            return 'Buying lunches'
          } else {
            return challengesData.challengeNames[i]
          }
        }
      }
    }
  }

  const updateSwitcherBottom = () => {
    const list = []
    if (props.campaignIsActive) {
      for (let i = 0; i < challengesData.challengeLandingIcons.length; i++) {
        list.push(
          <li
            key={i}
            style={{ justifyItems: 'center' }}
            className={challenges[i] === true ? 'challenge-selected' : ''}
            onClick={() => handleClick(i)}
          >
            <img
              key={i + 1000}
              style={{ width: '50px' }}
              src={challengesData.challengeLandingIcons[i]}
              alt="challengeLandingIcons"
            />
          </li>
        )
      }
    } else {
      for (let i = 0; i < challengesData.challengeDefaultLandingIcons.length; i++) {
        list.push(
          <li
            key={i}
            style={{ justifyItems: 'center' }}
            className={challenges[i] === true ? 'challenge-selected' : ''}
            onClick={() => handleClick(i)}
          >
            <img
              key={i + 1000}
              style={{ width: '50px' }}
              src={challengesData.challengeDefaultLandingIcons[i]}
              alt="challengeLandingIcons"
            />
          </li>
        )
      }
    }
    return list
  }

  return (
    <div className="challengeSwitcher-wrapper">
      <p className="challengeSwitcher-title">A month without</p>
      <p className="challengeSwitcher-des">{updateChallengeName()}</p>
      <ul className="challenge-wrapper">{updateSwitcherBottom()}</ul>
      <p className="challengeSwitcher-pledge">{checkUpdate()}</p>
    </div>
  )
}
