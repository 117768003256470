import '../../css/onboarding.css'

export default function GroupedChallenges(props: {
  challengeID: Number
  challengeName: string
  challengeImg: any
  challengeDesc: string
  challengeHashtag: string
  challengesHashtagLink: string
  ifSelected: boolean
  id: string
  updateSelect(id: string, select: boolean, challengeName: string, challengeID: Number): any
}) {
  const display = () => {
    props.updateSelect(props.id, !props.ifSelected, props.challengeName, props.challengeID)
  }
  return (
    <div onClick={display} className="stepper-challenge-wrapper">
      <div
        className={['challenge-innerWrapper', props.ifSelected === true ? 'selected' : null].join(
          ' '
        )}
      >
        <div className="challenge-name">{props.challengeName}</div>
        <div className="challenge-logo">
          <div className="challenge-logo-wrapper">
            <img src={props.challengeImg} alt="logo" />
          </div>
          <div style={{ paddingTop: 14 }}>
            <div className="challenge-desc">
              <p>{props.challengeDesc}</p>
              <a href={props.challengesHashtagLink} target="_blank" rel="noreferrer">
                {props.challengeHashtag}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
